import  React,{useState} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { TextField,InputLabel} from "@mui/material";
import {Market,Channels} from './json'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import postProductForm from './postProductForm';
import swal from 'sweetalert'; 
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAllProductConfiq } from '../../../../../Redux/reducers/setUpSlice';


export default function VerticalLinearStepper  ({props}){
    const {signupProduct} = postProductForm();
    const [scanitems, setCheckbox] = useState([]);
    const [image, setImage] = useState('');
    const [SelectALL, setSelectALL] = useState('checked');
    const [isCheckAll, setIsCheckAll] = useState(true);
    const [isCheck, setIsCheck] = useState([]);
  const dispatch = useDispatch()
     const [productData, setProductData] = useState({
      product_name:'',
      min_price:'',
      max_price:'',
      market:[]
      })
     const [inputValidation, setInputValidation] = useState({
            product_name:'',
            min_price:'',
            max_price:'',
            market:''
          });
      const handleChangeInput = (event) =>{
            event.preventDefault()
            setProductData((prevState) => ({
                  ...prevState,
                  [event.target.name]: event.target.value,
                }));
                setInputValidation((prevState) => ({
                  ...prevState,
                  [event.target.name]: "",
                
                }));
                
      }

      useEffect(()=>{
        let item = Market; 
        let arr = []; 
        if(Market){ 
          for(var i = 0;i < item.length;i++){
            arr.push(item[i].domain);  
            if(item.length - 1 === i) {  
              setCheckbox(arr);   
            }
          } 
        }
      },[]);

      const handleChangeImage = (e) =>{
          e.preventDefault()
          const imageFile = e.target.files[0]; 
          setImage(imageFile);      
      }


      const handleChangeCheck = (e,item) =>{
        let allitems = scanitems; 
        if(e.target.checked){  
          if (allitems.some((singleItem) => singleItem === item)) { 
            allitems = allitems.filter((singleItem) =>  
            singleItem !== item
            );
          } else {
            allitems.push(item);
          }  
        }else{
          const index = allitems.indexOf(item);
          if (index > -1) { // only splice array when item is found
            allitems.splice(index, 1); // 2nd parameter means remove one item only
            setSelectALL('')
          }
        } 
        setCheckbox(allitems);  
        console.log(scanitems)  
            /* setProductData((prevState) => ({
                  ...prevState,
                  [event.target.name]: [ event.target.value],
                }));
                setInputValidation((prevState) => ({
                  ...prevState,
                  [event.target.name]: "",
                }));
            console.log("%%%%%",event.target.value) */
                
      }

      const handleSelectAll = (e) =>{ 
        if(e.target.checked){    
          let item = Market; 
          let arr = []; 
          for(var i = 0;i < item.length;i++){
            arr.push(item[i].domain);  
            if(item.length - 1 === i) { 
              setCheckbox(arr); 
              console.log(scanitems)  
            }
          }  
          setSelectALL('checked')
        }else{   
          setSelectALL('')  
        }  
      }

      


      const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = async (event) => {
      event.preventDefault();
      var errorHandle = false;

    if (!productData?.product_name) {
      document.getElementById("product_name")?.focus();
      setInputValidation((prevState) => ({
        ...prevState,
        product_name: "This field is required.",
      }));
      errorHandle = true;
    }
    if (!productData?.min_price) {
      document.getElementById("min_price")?.focus();
      setInputValidation((prevState) => ({
        ...prevState,
        min_price: "This field is required.",
      }));
      errorHandle = true;
    }
    if (!productData?.max_price) {
      document.getElementById("max_price")?.focus();
      setInputValidation((prevState) => ({
        ...prevState,
        max_price: "This field is required.",
      }));
      errorHandle = true;
    }
   /*  if (!productData?.market?.length ) {
      document.getElementById("market")?.focus();
      setInputValidation((prevState) => ({
        ...prevState,
        market: "Please select atleast one market place or social media channels",
      }));
      errorHandle = true;
    } */
    if(!errorHandle){
      setActiveStep((prevActiveStep) => prevActiveStep + 1 )
    }
   

    
//setActiveStep((prevActiveStep) => prevActiveStep + 1 );
  };

  const handleSubmit  = async () => {  
    var errorHandle = false;  
    if (!productData?.product_name) {
      document.getElementById("product_name")?.focus();
      setInputValidation((prevState) => ({
        ...prevState,
        product_name: "This field is required.",
      }));
      errorHandle = true;
    }
    if (!productData?.min_price) {
      document.getElementById("min_price")?.focus();
      setInputValidation((prevState) => ({
        ...prevState,
        min_price: "This field is required.",
      }));
      errorHandle = true;
    }
    if (!productData?.max_price) {
      document.getElementById("max_price")?.focus();
      setInputValidation((prevState) => ({
        ...prevState,
        max_price: "This field is required.",
      }));
      errorHandle = true;
    }
    if(!errorHandle){
      // var items = {
      //   product_name:productData.product_name,
      //   min_price:productData.min_price,
      //   max_price:productData.max_price, 
      // }
    
      var items = new FormData();
          items.append("image", image);
          items.append("product_name", productData.product_name);
          items.append("min_price", productData.min_price);
          items.append("max_price", productData.max_price);
          items.append("market_places", scanitems); 
      const response = await signupProduct(items); 
      if(response.status == 201){  
        dispatch(getAllProductConfiq())
        setProductData((prevState) => ({
          ...prevState,
          product_name: '',
          min_price: '',
          max_price: '',
        })); 
        swal({
          title:"Product",
          text:"Added Successfully",
          icon:"success",
          button:"ok",  
        })
      } else{
        swal({
          title:"Product",
          text:"Something went wrong, try again later !",
          icon:"error",
          button:"ok",  
        }) 
      }      
    }
  }

 

  /* const handleSelectAll = e => { 
    setIsCheckAll(!isCheckAll); 
    setIsCheck(Market.map(li => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };
 */
  const handleClick = e => {
    const { id, checked } = e.target; 
    setIsCheck([...Market, id]);
    if (!checked) {
      setIsCheck(Market.filter(item => item !== id));
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
      const steps =  [
            {
              label: 'Fill out the product details',
              description: <div >
                  <form onSubmit={handleNext} className='flex flex-col gap-4'>
                  <div>     
                   <TextField
                       id="product_name"
                        name="product_name"
                        label="Product Name*"
                        variant="outlined"
                        type="text"
                        fullWidth
                        value={productData?.product_name}
                        placeholder='Product Name or search keyword'
                        sx={{ width: 600 }}
                        onChange={handleChangeInput}
                      />
                       <InputLabel className='text-orange-600 text-sm'>
                      <p className='text-orange-600 text-sm'>{inputValidation?.product_name}</p>
                    </InputLabel>
                    </div>  
                  <div>     
                   {/* <TextField
                       id="product_file"
                        name="product_file" 
                        variant="outlined"
                        type="file"
                        fullWidth
                        value={productData?.product_file} 
                        sx={{ width: 600 }}
                        onChange={handleChangeInput}
                      /> */}
                      <Button
                        variant="contained"
                        component="label"
                      > 
                        <input
                          type="file"
                          onChange={handleChangeImage}
                        />
                      </Button>
                       <InputLabel className='text-orange-600 text-sm'>
                      <p className='text-orange-600 text-sm'>{inputValidation?.product_file}</p>
                    </InputLabel>
                    </div>  
                      <div className='flex items-center gap-3'>
                          {/*   <p>Price Range</p> */}
                       <div>
                        <TextField
                        name="min_price"
                        label="Min Price*"
                        variant="outlined"
                        fullWidth
                        sx={{ width: 200 }}
                        value={productData?.min_price}
                        onChange={handleChangeInput}
                      />
                       <InputLabel className='text-orange-600 text-sm'>
                       <p className='text-orange-600 text-sm'>{inputValidation?.min_price}</p>
                    </InputLabel>
                    </div>
                      <p>to</p>
                      <div>
                      <TextField
                        name="max_price"
                        label="Max Price*"
                        variant="outlined"
                        fullWidth
                        sx={{ width: 200 }}
                        value={productData?.max_price}
                        onChange={handleChangeInput}
                      />
                       <InputLabel className='text-orange-600 text-sm'>
                       <p className='text-orange-600 text-sm'>{inputValidation?.max_price}</p>
                    </InputLabel>
                    </div>
                      </div>
                      <div>
                            <p>Market Places to Scan</p> 
                            {/* <span   className="inline left-0 w-2/4">
                               <p ><Checkbox   onChange={(e)=>{handleSelectAll(e)}}  checked={SelectALL ?? ''} />All</p>
                            </span> */}
                            {/* {SelectALL == 'checked' &&
                              Market.map((item) =>(
                              <span   className="inline left-0 w-2/4">  
                                <p > 
                                  <Checkbox
                                    key={'chks'+item.id}
                                    type="checkbox" 
                                    className='selectedA'
                                    id={'chks'+item.id}
                                    onChange={()=>{handleChangeremove(item.domain)}}
                                    checked="checked"
                                  />
                                  {item.domain}
                                </p> 
                              </span>
                            ))
                            }
                            {SelectALL == '' && */}
                             { Market.map((item) =>(
                               <span className="inline left-0 w-2/4" key={item.id}>  
                                <p > 
                                  <Checkbox
                                    key={'chk'+item.id}
                                    type="checkbox" 
                                    className='selectedA'
                                    id={'chk'+item.id}
                                    defaultChecked
                                    onChange={(e)=>{handleChangeCheck(e,item.domain)}}
                                    // isChecked={scanitems.includes(item.domain)}    
                                  />
                                  {item.domain}
                                </p> 
                              </span>
                            )) }
                          {/*   } */}
                      </div>
                    {/*   <div>
                            <p>Social Media Channels to Scan</p>
                            {
                               Channels.map((item) =>(
                            <span key={item.id}>
                               <p className='opacity-25'>{item.check}{item.domain}</p>
                            </span>
                            ))
                             }
                      </div> */}
                      <InputLabel className='text-orange-600 text-sm'>
                      <p className='text-orange-600 text-sm'>{inputValidation?.market}</p>
                    </InputLabel>
                      </form>
              </div>
          
            },
           /*  {
              label: 'Authorised Marketplace Seller Details (Optional)',
              description:<div className='flex gap-3'>
              <TextField
                     name="market"
                     label="Market Place"
                     variant="outlined"
                     fullWidth
                     value={productData?.market}
                     sx={{ width: 300 }}
                   />
                   <TextField
                     name="authorised"
                     label="Authorised sellers link or name"
                     variant="outlined"
                     fullWidth
                     sx={{ width: 500 }}
                   />
           </div>,
               
            },
            {
              label: 'Done',
              description: `Your product has been successfully added`,
            }, */
          ];    
  

  return (
    <Box sx={{ maxWidth: 900 }} className="flex flex-col  gap-3  p-4   mt-2  bg-white rounded-3xl shadow-md  ">
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography component={'span'}  variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography component={'span'} >{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div className='flex  mt-2 gap-3'>
                <Button
                    variant="contained"
                    onClick={handleSubmit} 
                    className="w-40"
                  >
                    Submit
                  </Button>
                {/* <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                    className="w-40"
                  >
                    Back
                  </Button> */}
                 {/*  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                    className="w-40"
                  >
                    {index === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button> */}
                  
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          
          <Button onClick={handleReset} variant="contained" style={{backgroundColor: "#fff", color:'#000000de'}} sx={{ mt: 1, mr: 1 }}>
            Addmore
          </Button>
          <Button  variant="contained" sx={{ mt: 1, mr: 1 }}>
            Next
          </Button>
        </Paper>
      )}
    </Box>
  );
}
