import axios from 'axios';
import API_END_POINTS from './integrations'
import {RESPONSE_CODES} from './definations'
import { setAuthCredentials } from '../Web/Common/authUtils'

axios.interceptors.request.use(
  (config) => {
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem('refreshToken');
    if (
      refreshToken
      && error.response.status === RESPONSE_CODES.UNAUTHORIZED
      && !originalRequest._retry
    ) {
      originalRequest._retry = true;

      return axios
        .post(API_END_POINTS.userTokenRefresh, {'refresh': refreshToken})
        .then((res) => {
          if (res.status === RESPONSE_CODES.OK) {
            setAuthCredentials(res.data.access)
            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  },
);

export default axios;
