import API_END_POINTS from '../../../../../Config/integrations';
import { postWithResponseObject } from '../../../../Common/restApisFunctions';

const postProductForm = () => {
  const signupProduct = async (item) => { 
    const response = await postWithResponseObject(
      API_END_POINTS.productConfigPost,
      JSON.stringify(item),
    );
    return response;
  };
  return {
    signupProduct
  };
};

export default postProductForm;