import React, { useEffect, useState, } from "react";
import AddIcon from "@mui/icons-material/Add"; 
import { TextField, Autocomplete,Dropdown, Box, Button,MenuItem,Select,FormGroup,FormControlLabel,CircularProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { TabTitle } from "../../../../../Config/generalFunctions";
import postDomainForm from './services'
import getAllDomains from './getAllDomains';
import API_END_POINTS from '../../../../../Config/integrations';
import axios from 'axios'  
import swal from 'sweetalert';
import { useDispatch, useSelector } from "react-redux";
import { getAllDomainConfiq } from "../../../../../Redux/reducers/setUpSlice";
import nextForm from "./nextForm";



const Index = ({scan}) => {
  TabTitle('Fake Domain Scanner Service')
  const dropdown = ["https", "http"];
  const [serviceList, setServiceList] = useState([{ service: "" }]);  
  const [protocol, setProtocol] = useState(" ")
  const [domain, setDomain] = useState(" ")
  const [alldomain, setAllDomain] = useState([]) 
  const [ishttp, setishttp] = useState(" ")
  const {signupdomain} = postDomainForm();
  const {nextData} = nextForm();
  const [valid, setValid] = useState("")
  const [Value, setValue] = useState("https");
  const [nextLink, setNextDomain] = useState('');
  const [previousLink, setPreviousDomain] = useState('');
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch()
  const allDomainConfiq = useSelector((state) => state.confiqData.allDomainConfiq)
  const getData = async () => {
    setLoader(true);

    if (allDomainConfiq) {
      setLoader(false);
    } 
    if (allDomainConfiq.results.length > 0) {
      setAllDomain([...allDomainConfiq.results]);
      if (allDomainConfiq.next_page) {
        setNextDomain(allDomainConfiq.next_page); 
      }else{
        setNextDomain(); 
      } 
      if (allDomainConfiq.prev_page) {
        setPreviousDomain(allDomainConfiq.prev_page); 
      }else{
        setPreviousDomain(); 
      } 
    }
  } 
  useEffect(()=>{
    getData();
  }, [allDomainConfiq]) 

 

  const handleChangeDropdown =(e) =>{
    setishttp(e.target.value);
  }

  const handleChange =(e) =>{
    setDomain(e.target.value); 
  }

  const handleAddmore =() =>{ 
      setServiceList([...serviceList,{service:""}])
  }

  const handleRemove = (index) =>{
      const list = [...serviceList]
      list.splice(index,1);
      setServiceList(list)
  }

  //API Call
  const postDomain = async () =>{
    // const new_domain = domain.replace(/https?:\/\/(?:www\.)?/, "").split(".");
    // const response = await signupdomain(new_domain[0],new_domain[1]); 
    if(domain){
    setAllDomain([]); 
    const response = await signupdomain(domain); 
      dispatch(getAllDomainConfiq())
    if(response.status == 201){
      swal({
        title:"Domain",
        text:"Added Successfully",
        icon:"success",
        button:"ok",  
      })
    } else{
      swal({
        title:"App",
        text:"Something went wrong, try again later !",
        icon:"error",
        button:"ok",  
      }) 
    }      
    setDomain(''); 
  }else{
    swal({
      title:"Enter Domain Name",
      text:"For eg. domainname.com",
      icon:"error",
      button:"ok",  
    }) 
  }
  }

  const next = async () =>{
    const response = await nextData(nextLink);
    if (response.results.length > 0) {
      setAllDomain([]);
      setAllDomain([...response.results]);
      if (response.next_page) {
        setNextDomain(response.next_page); 
      }else{
        setNextDomain(); 
      } 
      if (response.prev_page) {
        setPreviousDomain(response.prev_page); 
      }else{
        setPreviousDomain(); 
      } 
    }
  }
  const previous = async () =>{ 
    const response = await nextData(previousLink);
    if (response.results.length > 0) {
      setAllDomain([]);
      setAllDomain([...response.results]);
      if (response.next_page) {
        setNextDomain(response.next_page); 
      }else{
        setNextDomain(); 
      } 
      if (response.prev_page) {
        setPreviousDomain(response.prev_page); 
      }else{
        setPreviousDomain(); 
      }  
    }
  }
 
    return (
      <>
    <div className="flex justify-between">
      <div className="flex flex-col  gap-3   p-4  mt-2  bg-white rounded-3xl shadow-md overflow-hidden w-2/4">
      {alldomain.length > 0 ? (Object.entries(alldomain).map((val, key) => {
                                        return ( 
                                          <div key={key} className="flex items-center justify-around pt-5 gap-5">   
           <Select readOnly
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={Value}
              label="ishttp"  
            >
              <MenuItem value={'http'}>http://</MenuItem>
              <MenuItem value={'https'}>https://</MenuItem> 
            </Select>
            <TextField readOnly
              name="domain"
              //label="Domain"
              variant="outlined"
              fullWidth
              sx={{ width: 300 }}
              value={val[1].apex_domain_name +'.'+ val[1].top_level_domain} 
            />
            <DeleteIcon className="text-primary cursor-pointer" />
          </div>
          )
      })):'No data found !'
    }
     {loader == true && <div  className="flex items-end justify-around pt-5 gap-5"><CircularProgress /></div>}
     <br></br>
        <hr/>
        <div   className="ml-15 j pt-1 gap-5"> 
          <div>
            <h2>Add new</h2>
          </div>
        </div>
        {serviceList.map((singleService, index) => (
          <div key={index} className="flex items-center justify-around pt-1 gap-5">  
          {/* <FormControlLabel control={<Checkbox  onChange={handleChangeCheckbox} />} label="Select For scan" />   */}
           <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select" 
              value={Value}
              label="ishttp"
              onChange={handleChangeDropdown}
            >
              <MenuItem selected value={'http'}>http://</MenuItem>
              <MenuItem value={'https'}>https://</MenuItem> 
            </Select>
            <TextField
              name="domain" 
              variant="outlined"
              fullWidth
              sx={{ width: 300 }}
              value={domain}
              onChange={(e) =>handleChange(e, index)}
            />
           {/*  <DeleteIcon className="text-primary cursor-pointer"  onClick={() => handleRemove(index)}/> */}
            <Button variant="contained"  style={{textTransform: 'none'}} onClick={ () => postDomain(index)}>Submit</Button>
          </div>
        ))}
        {/* <div className="flex items-center gap-2" >
          <AddIcon className="text-primary cursor-pointer" onClick={handleAddmore} />
          <p className="text-primary" >Add a domain</p>
        </div> */}
        {/* <Box>
          <Button variant="contained" className="capitalize" >
            Scan
          </Button>
        </Box> */}
        <div className='d-flex'>
        {previousLink && <Button variant="contained"  style={{textTransform: 'none'}} onClick={ () => previous()}>Previous</Button>} &nbsp;
        {nextLink && <Button variant="contained"  style={{textTransform: 'none'}} onClick={ () => next()}>Next</Button>}
        </div>
      </div>
      <div className="flex flex-col  gap-3 mx-4 p-4  mt-2  bg-white rounded-3xl shadow-md overflow-hidden w-2/4 ">
        <h1>Help?</h1>
        <p className="text-neutral-400">Fake domain scanner</p>
        <div className="flex flex-col gap-3">
          <p className="domainscan_guide">
            Click on “Add a domain” and enter the domain name in the text box.
          </p>
          <p className="domainscan_guide">
            Step 2: Click on “Verify” and enter a company email address you have
            access to that belongs to the same domain.
          </p>
          <p className="domainscan_guide">
            Step 3: Click on “Verify email” and a verification request will be
            sent to Truviss customer support.
          </p>
          <p className="domainscan_guide">
            Step 4: Once the email is verified you can proceed to setup the Scan
            cycle..
          </p>
          <p className="domainscan_guide">Step 6: Setup the Scan cycle.</p>
        </div>
      </div>
    </div>
    </>
  );
};

export default Index;
