import './App.css';
import React, {  } from 'react';
import {BrowserRouter,Routes,Route,Navigate} from 'react-router-dom'
//import { ThemeProvider } from "@mui/material/styles";
//import theme from "./theme";
import LogIn from './Web/Components/LogIn'
import Onboarding from './Web/Components/Onboarding'
import Dashboard from './Web/Components/Dashboard'
//import Apps from './Web/Pages/Apps'
//import Alarms from './Web/Pages/Alarms'
//import Domains from './Web/Pages/Domains'
//import Products from './Web/Pages/Products'
//import Notifications from './Web/Pages/Notifications'
//import Setup from './Web/Pages/Setup'
//import Socialmedia from './Web/Pages/SocialMedia'
import Signup from './Web/Components/Signup'
// import Server from './Server'
//import {useAuthContext} from './Config/useAuthContext'
import {  isUserAuthorized } from '././Web/Common/authUtils'
// import  {SimpleBackdrop} from './././Web/Common/LoaderBackDrop'
import PageNotfound from './Web/Common/pageNotfound'
// import { ThemeProvider } from 'styled-components';
//import { logOut } from './Web/Common/authUtils';


function App() {
  const userAuth =  isUserAuthorized;
  // console.log('auth', checkForAuth(), isUserAuthorized)
  // const { user } = useAuthContext()
  // window.location.reload(false);
  return (
    <div className="App">
      {/* <ThemeProvider theme={theme}> */}
      <BrowserRouter >
      <Routes>
      {/*# <Route path="/spinner" exact element={ <SimpleBackdrop/>}/> */}
        <Route path="/" exact element={<Navigate to="/dash_board"/>}/>
        <Route path="/logIn" exact element={ userAuth ? <Navigate to="/dash_board/:currenttab"/> :<LogIn/> }/>
        <Route path="/onboarding" exact element={  userAuth ? <Onboarding/> : <Navigate to="/logIn"/>}/>
        <Route path="/dash_board" exact element={ userAuth ? <Dashboard/> : <Navigate to="/logIn"/>}/> 
        <Route path="/dash_board/:currenttab/" exact element= { userAuth ? <Dashboard/> : <Navigate to="/logIn"/>}/>
        <Route path="/dash_board/:currenttab/:subval" exact element= { userAuth ? <Dashboard/> : <Navigate to="/logIn"/>}/>
        <Route path="/notFound" exact element={ <PageNotfound/>}/>
        <Route path="/signup" exact element={ <Signup/>}/>
       
        {/* Sidebar menulist */}
        {/* <Route path="/dash_board/apps"  exact element={ <><Home/><Apps/></>}/>
        <Route path="/dash_board/alarms" exact element={ <><Home/><Alarms/></>}/>
        <Route path="/dash_board/domains" exact element={ <><Home/><Domains/></>}/>
        <Route path="/dash_board/products" exact element={ <><Home/><Products/></>}/>
        <Route path="/dash_board/notifications" exact element={ <><Home/><Notifications/></>}/>
        <Route path="/dash_board/social_media" exact element={ <><Home/><Socialmedia/></>}/>
        <Route path="/dash_board/setup" exact element={ <><Home/><Setup/></>}/> */}
         {/* <Route path="/dash_board/setup/server" exact element={ <><Home/><Server/></>}/> */}
      </Routes>
      </BrowserRouter>
      {/* </ThemeProvider> */}
    </div>
  );
}


export default App;
