import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import API_END_POINTS from "../../Config/integrations";
import { getApiResponseObject } from "../../Web/Common/restApisFunctions";
const initialState = {
    allDomainConfiq: null,
    allSocialMediaConfiq: null,
    allAppConfiq: null,
    allProductConfiq: null
}
export const getAllDomainConfiq = createAsyncThunk(
    'Confiq/get',
    async () => {
        const res = await getApiResponseObject(
            API_END_POINTS.domainsConfigGet,
            JSON.stringify(),
        );
        return res
    })
export const getAllSocialMediaConfiq = createAsyncThunk(
    'socialConfiq/get',
    async () => {
        const res = await getApiResponseObject(
            API_END_POINTS.socialConfigGet,
            JSON.stringify(),
        );
        return res
    })
export const getAllAppConfiq = createAsyncThunk(
    'appConfiq/get',
    async () => {
        const res = await getApiResponseObject(
            API_END_POINTS.appConfigGet,
            JSON.stringify(),
        );
        return res
    })
export const getAllProductConfiq = createAsyncThunk(
    'productConfiq/get',
    async () => {
        const res = await getApiResponseObject(
            API_END_POINTS.productConfigGet,
            JSON.stringify(),
        );
        return res
    })
export const setUpSlice = createSlice({
    name: 'setUp',
    initialState,
    reducers: {

    },
    extraReducers: {

        [getAllDomainConfiq.fulfilled]: (state, { payload }) => {

            state.allDomainConfiq = payload
        },
        [getAllSocialMediaConfiq.fulfilled]: (state, { payload }) => {

            state.allSocialMediaConfiq = payload
        },
        [getAllAppConfiq.fulfilled]: (state, { payload }) => {

            state.allAppConfiq = payload
        },
        [getAllProductConfiq.fulfilled]: (state, { payload }) => {

            state.allProductConfiq = payload
        },

    },
})



export default setUpSlice.reducer