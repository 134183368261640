import React, { useState,useEffect } from "react";
import { Box, Button,CircularProgress,FormControlLabel,Checkbox } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { TextField, Autocomplete } from "@mui/material";
import { TabTitle } from "../../../../../Config/generalFunctions";
import getAllsocial from './getAllsocial';
import getsocialcan from './service'
import nextForm from './nextForm';
import prevForm from './prevForm';
import swal from 'sweetalert';
import { useSelector } from "react-redux";
const Index = () => {
  TabTitle('Fake Social Media Scanner Service')
  const [loader, setLoader] = useState(false);
  const dropdown = ["Day", "Week"];
  const dropdown1 = ["Immediately", "Next Schedule"];
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  const [toggle, setToggle] = useState(false);
  const [allsocial, setAllsocial] = useState([])
  const [nextLink, setNextApp] = useState('');
  const {nextData} = nextForm();
  const {prevdata} = prevForm();
  const [previousLink, setPreviousApp] = useState('');
  const [scanitems, setCheckbox] = useState([]);
  const {scanSoc} = getsocialcan();
  const allSocialMediaConfiq = useSelector((state) => state.confiqData.allSocialMediaConfiq)
  const getData = async () => {
    setLoader(true);
    if (allSocialMediaConfiq) {
      setLoader(false);
    }
    if (allSocialMediaConfiq.results.length > 0) {
      setAllsocial(allSocialMediaConfiq.results)
      if (allSocialMediaConfiq.next_page) {
        setNextApp(allSocialMediaConfiq.next_page); 
      }else{
        setNextApp(); 
      } 
      if (allSocialMediaConfiq.prev_page) {
        setPreviousApp(allSocialMediaConfiq.prev_page); 
      }else{
        setPreviousApp(); 
      }  
    }
  }
  const handleChangeCheck = (item) => {

    setCheckbox(scanitems.includes(item) ? scanitems.filter(e => e !== item) : [...scanitems, item]);

  };
  useEffect(()=>{
    getData();
  }, [allSocialMediaConfiq]) 
  
  const next = async () =>{ 
    setAllsocial([]); 
    setLoader(true);
    const response = await nextData(nextLink);  
    if (response.results.length > 0) {
      setAllsocial([...response.results]);
      if (response.next_page) {
        setNextApp(response.next_page); 
      }else{
        setNextApp(); 
      } 
      if (response.prev_page) {
        setPreviousApp(response.prev_page); 
      }else{
        setPreviousApp(); 
      }  
    }
    setLoader(false);
  }
  const previous = async () =>{ 
    setAllsocial([]); 
    setLoader(true);
    const response = await prevdata(previousLink);
    if (response.results.length > 0) {
      setAllsocial([...response.results]);
      if (response.next_page) {
        setNextApp(response.next_page); 
      }else{
        setNextApp(); 
      } 
      if (response.prev_page) {
        setPreviousApp(response.prev_page); 
      }else{
        setPreviousApp(); 
      }  
    }
    setLoader(false);
  }
  

  const scan = async () =>{ 
    if(scanitems.length > 0){
      for(var i=0;i<scanitems.length;i++){
        var id = scanitems[i];
        const response = await scanSoc(id); 
        if(i === scanitems.length -1){ 
          let status = response.status; 
          if (status == 201) {
            swal({
              title:"Scan Cycle",
              text:"Started Successfully",
              icon:"success",
              button:"ok",  
            }).then(()=>{
              setCheckbox([])
            });
          }else {
            swal({
                title:response.status,
                text:"Something went wrong, try again later ...",
                icon:"error", 
                button:"ok",  
              })
          }
        }
      }
    }else{
      swal({
        title:"Please select at least one",
        text:"Before starting scan",
        icon:"error", 
        button:"ok",  
      })
    } 
    
  }
  
  return (
    <div className="flex justify-between">
      <div className="flex flex-col  gap-3   p-4  mt-2  bg-white rounded-3xl shadow-md overflow-hidden w-2/4">
        {allsocial.length > 0 ? (Object.entries(allsocial).map((val, key) => {
          return (  
            <div key={key} className='flex  items-center   pt-5 gap-5'>
              <FormControlLabel key={key} control={<Checkbox value={val[1].id} checked={scanitems.includes(val[1].id)} name={val[1].social_network} onChange={(e) => { handleChangeCheck(val[1].id) }} />} label="" />
             <h3>{val[1]?.social_network.charAt(0).toUpperCase() + val[1]?.social_network.slice(1)} , Hastag : { val[1].keyword_hashtag ?? '-'} </h3>
             </div>
            
          )})):'Loading ...'}
           {loader == true && <div  className="flex items-end justify-around pt-5 gap-5"><CircularProgress /></div>}
           <div className='d-flex'>
           {previousLink && <Button variant="contained"  style={{textTransform: 'none'}} onClick={ () => previous()}>Previous</Button>} &nbsp;
           {nextLink && <Button variant="contained"  style={{textTransform: 'none'}} onClick={ () => next()}>Next</Button>}
           </div>
           <Box>
            <Button variant="contained" className="capitalize "  onClick={() => scan()}>
              Start Scan
            </Button>
          </Box>
        {/* <div className="flex items-center gap-2">
          <p className="text-neutral-600">scan enabled</p>
          <Switch {...label} onClick={() => setToggle(!toggle)}/>
        </div>
        {toggle && 
        <div className="flex items-center flex-col gap-5">
        <div className="flex items-center ">
          <p className="text-neutral-600">Repeat scan every</p>
          <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-weight"
              aria-describedby="outlined-weight-helper-text"
              value={1}
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </FormControl>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            fullWidth
            options={dropdown}
            sx={{ width: 200 }}
            value={dropdown?.[0]}
            renderInput={(params) => <TextField {...params} fullWidth  />}
          />
        </div>
            <Autocomplete
            disablePortal
            id="combo-box-demo"
            fullWidth
            options={dropdown1}
            sx={{ width: 665 }}
            renderInput={(params) => (
              <TextField {...params} label="startscan"  />
            )}
          />
      </div>
        }
        <Box>
          <Button variant="contained" className="capitalize ">
            Save
          </Button>
        </Box> */}
      </div>
      <div className="flex flex-col  gap-3 mx-4 p-4  mt-2  bg-white rounded-3xl shadow-md overflow-hidden w-2/4">
        <h1>Help?</h1>
        <p className="text-neutral-400">Fake Social Media scanner</p>
        <div className="flex flex-col gap-2">
          <p className="domainscan_guide">
            Click on “Add a domain” and enter the domain name in the text box.
          </p>
          <p className="domainscan_guide">
            Step 2: Click on “Verify” and enter a company email address you have
            access to that belongs to the same domain.
          </p>
          <p className="domainscan_guide">
            Step 3: Click on “Verify email” and a verification request will be
            sent to Truviss customer support.
          </p>
          <p className="domainscan_guide">
            Step 4: Once the email is verified you can proceed to setup the Scan
            cycle..
          </p>
          <p className="domainscan_guide">Step 6: Setup the Scan cycle.</p>
        </div>
      </div>
    </div>
  );
};

export default Index;
