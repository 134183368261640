import API_END_POINTS from '../../../../../Config/integrations';
import { postWithResponseObject } from '../../../../Common/restApisFunctions';

const getProductScan = () => { 
         
      const scanPro = async (id) => {
        let item =  {
          product:id, 
        };
        const response = await postWithResponseObject(
          API_END_POINTS.productScanPost,
          JSON.stringify(item),
        );
        return response;
      };
      return {
        scanPro
      };
};  
export default getProductScan;