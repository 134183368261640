import React, { useState,useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button } from "@mui/material";
import { TextField, Autocomplete,CircularProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { TabTitle } from "../../../../../Config/generalFunctions";
import getAllApps from './getAllApps';
import signupAppService from './services';
import swal from 'sweetalert';
import nextForm from './nextForm';
import prevForm from './prevForm';
import { useDispatch, useSelector } from "react-redux";
import { getAllAppConfiq } from "../../../../../Redux/reducers/setUpSlice";

const Index = () => {
  TabTitle('Fake Apps Scanner Service')
  const dropdown = ["Android", "IOS","Desktop"];
  const [serviceList, setServiceList] = useState([{ service: "" }]);
  const [device, setDevice] = useState("")
  const [appName, setApp] = useState("")
  const [valid, setValid] = useState("")
  const [nextLink, setNextApp] = useState('');
  const [previousLink, setPreviousApp] = useState('');
  const [showbar, setshowbar] = useState(true)
  const [allApps, setAllApps] = useState([])
  const {signupApps} = signupAppService();
  const {nextData} = nextForm();
  const {prevdata} = prevForm();
  const [loader, setLoader] = useState(false);
  const handleAddmore =() =>{
    setServiceList([...serviceList, { service: "" }])
  }
  const dispatch = useDispatch()
  const allAppConfiq = useSelector((state) => state.confiqData.allAppConfiq)

  const getData = async () => {
    setLoader(true);

    if (allAppConfiq) {
      setLoader(false);
    }
    if (allAppConfiq.results.length > 0) { 
      
      setAllApps([...allAppConfiq.results]);
      if (allAppConfiq.next_page) {
        setNextApp(allAppConfiq.next_page); 
      }else{
        setNextApp(); 
      } 
      if (allAppConfiq.prev_page) {
        setPreviousApp(allAppConfiq.prev_page); 
      }else{
        setPreviousApp(); 
      }  
    }
    setshowbar(false);
  } 
  useEffect(()=>{
    getData();
  }, [allAppConfiq]) 

  const postApps = async () =>{  
    //setAllApps([]); 
    var selecteddevice = '';
    if(device == 'Android'){
      var selecteddevice = 1;
    }else if(device == 'IOS'){
      var selecteddevice = 2; 
    }else if(device == 'Desktop'){
      var selecteddevice = 3; 
    }  
    if(appName && selecteddevice){
      const response = await signupApps(appName,selecteddevice);
      if(response.status == 201){
        dispatch(getAllAppConfiq())
        swal({
          title:"App",
          text:"Added Successfully",
          icon:"success",
          button:"ok",  
        })
      } else{
        swal({
          title:"App",
          text:"Something went wrong, try again later !",
          icon:"error",
          button:"ok",  
        }) 
      }   
      setDevice(''); 
      setApp(''); 
    }else{
      if(appName == ''){ 
        swal({
          title:"Enter App Name",
          text:"For eg. Pubg",
          icon:"error",
          button:"ok",  
        }) 
      }
      if(selecteddevice == ''){ 
        swal({
          title:"Select Device Type", 
          icon:"error",
          button:"ok",  
        }) 
      }
    }
  }

  const handleRemove = (index) =>{
      const list = [...serviceList]
      list.splice(index,1);
      setServiceList(list)
  }
  const handleChange = (e) =>{ 
    setApp(e.target.value);
  }
  const next = async () =>{ 
    const response = await nextData(nextLink);  
    if (response.results.length > 0) {
      setAllApps([]); 
      setAllApps([...response.results]);
      if (response.next_page) {
        setNextApp(response.next_page); 
      }else{
        setNextApp(); 
      } 
      if (response.prev_page) {
        setPreviousApp(response.prev_page); 
      }else{
        setPreviousApp(); 
      }  
    }
  }
  const previous = async () =>{ 
    const response = await prevdata(previousLink);
    if (response.results.length > 0) {
      setAllApps([]); 
      setAllApps([...response.results]);
      if (response.next_page) {
        setNextApp(response.next_page); 
      }else{
        setNextApp(); 
      } 
      if (response.prev_page) {
        setPreviousApp(response.prev_page); 
      }else{
        setPreviousApp(); 
      }  
    }
  }
  

  return (
    <>
    <div className="flex justify-between">
      <div className="flex flex-col  gap-3  p-4  mt-2  bg-white rounded-3xl shadow-md overflow-hidden w-2/4">
      {allApps.length > 0 ? (Object.entries(allApps).map((val, key) => {
        if(val[1].device == 1){
          var selected = 'Android';
        }else if(val[1].device == 2){
          var selected = 'IOS'; 
        }else if(val[1].device == 3){
          var selected = 'Desktop'; 
        }
                                        return ( 
                                          <div key={key} className="flex items-center justify-around pt-5 gap-5">   
            <TextField
              label="Application Name" 
              readOnly
              name="app" 
              variant="outlined"
              fullWidth
              sx={{ width: 300 }}
              value={val[1].app_name}
              onChange={(e) =>handleChange(e, key)}
            />
            <Autocomplete
              readOnly
              disablePortal
              id={"combo-box-demo"+key}
              fullWidth
              options={dropdown}
              name="protocol"
              value={selected} 
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Device"  />
              )}
            />
            <DeleteIcon className="text-primary cursor-pointer" />
          </div>
          )
      })):'No data found !'
    } 
     {loader == true && <div  className="flex items-end justify-around pt-5 gap-5"><CircularProgress /></div>}
      <div className='d-flex'>
      {previousLink && <Button variant="contained"  style={{textTransform: 'none'}} onClick={ () => previous()}>Previous</Button>} &nbsp;
      {nextLink && <Button variant="contained"  style={{textTransform: 'none'}} onClick={ () => next()}>Next</Button>}
    </div>
    {/* {allApps.length == 0 && showbar == true && <div  className="flex items-end justify-around pt-5 gap-5"><CircularProgress /></div>} */}
        <hr/>
        <div>
          <h2>Add new</h2>
        </div>


        {serviceList.map((singleService, index) => (
          <div key={index} className="flex items-center justify-around pt-5 gap-5">
            <TextField
              name="domain"
              label="Application name*"
              variant="outlined"
              fullWidth
              sx={{ width: 300 }}
              value={appName}
              onChange={handleChange}
            />
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              fullWidth
              options={dropdown}
              name="device"
              value={device}
              onChange={(event, newValue) => {
                setDevice(newValue)
              }}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Device"  />
              )}
            />
           {/*  <DeleteIcon className="text-primary cursor-pointer"  onClick={() => handleRemove(index)}/> */}
            <Button  className="text-primary cursor-pointer " style={{textTransform: 'capitalize'}} variant="contained" onClick={ () => postApps(index)}>Submit</Button>
          </div>
        ))}
       {/*  <div className="flex items-center gap-2" >
          <AddIcon className="text-primary cursor-pointer" onClick={handleAddmore} />
          <p className="text-primary" >add a domain</p>
        </div>
        <Box>
          <Button variant="contained" className="capitalize" >
            Next
          </Button>
        </Box> */}
         
      </div>
      <div className="flex flex-col  gap-3 mx-4 p-4  mt-2  bg-white rounded-3xl shadow-md overflow-hidden w-2/4 ">
        <h1>Help?</h1>
        <p className="text-neutral-400">Fake domain scanner</p>
        <div className="flex flex-col gap-3">
          <p className="domainscan_guide">
            Click on “Add a domain” and enter the domain name in the text box.
          </p>
          <p className="domainscan_guide">
            Step 2: Click on “Verify” and enter a company email address you have
            access to that belongs to the same domain.
          </p>
          <p className="domainscan_guide">
            Step 3: Click on “Verify email” and a verification request will be
            sent to Truviss customer support.
          </p>
          <p className="domainscan_guide">
            Step 4: Once the email is verified you can proceed to setup the Scan
            cycle..
          </p>
          <p className="domainscan_guide">Step 6: Setup the Scan cycle.</p>
        </div>
      </div>
    </div>
    </>
  );
};

export default Index;
