import API_END_POINTS from '../../../../../Config/integrations';
import { postWithResponseObject } from '../../../../Common/restApisFunctions';

const getDomainScan = () => { 
         
  const scanDomain = async (id, uuid) => {
        let item =  {
          domain_config:id, 
          started_by: uuid,
          "is_started": true,
          "is_completed": true
        } ;
        const response = await postWithResponseObject(
          API_END_POINTS.domainsScanPost,
          JSON.stringify(item),
        );
        return response;
      };
      return {
            scanDomain
      };
};  
export default getDomainScan;