import React, { useRef, useState} from "react";
import {useLocation} from 'react-router-dom'
// import {authContext} from '../../../Config/contextAPI/authProvider'
import {Link} from 'react-router-dom'
import "./style.scss";
import log from "../../../Assets/images/login-screen.png";
//import IconButton from "@material-ui/core/IconButton";
//import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
//import Visibility from "@material-ui/icons/Visibility";
//import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputLabel from "@mui/material/InputLabel";
//import PasswordField from 'material-ui-password-field'
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { Box, Button,CircularProgress } from "@mui/material";
//import PropTypes from "prop-types";
import { TabTitle } from "../../../Config/generalFunctions";
//import Onboarding from '../Onboarding'
//import Dashboard from '../Dashboard'
// import {API_END_POINTS} from '../../../Config/integrations'
import {RESPONSE_CODES} from '../../../Config/definations'
import UseLoginForm from './services';
import { setAuthCredentials } from "../../Common/authUtils";
import {reloadWindow} from '../../Common/authUtils'
import Signup from '../Signup'
import swal from 'sweetalert';
const Index = (props) => {
  TabTitle(" Sign In | Truviss ");
  const [loader, setLoader] = useState(false);
  const userRef = useRef()
  const errRef = useRef()
  const location = useLocation()

  const [disabled, setDisabled] = useState(false);
  const [username, setName] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState([])
  const [successMsg, setSuccessMsg] = useState([])
  const [signup, setUignup] = useState(false)
 
  const {loginUser } = UseLoginForm();

  // const [inputValidation, setInputValidation] = useState({
  //   username: "",
  //   password: "",
  // });
 
  const handleClickValidation = async (e) => {
    setLoader(true)
    setErrMsg('')
    const response = await loginUser(username, password);
     let error = response.status
    if (response && (response.status === RESPONSE_CODES.OK)) {
      const { access, refresh } = response.data.user.tokens;
      setAuthCredentials(access, refresh);
      //setSuccessMsg("You are Logged in Successfully")
      setErrMsg(false)
      setLoader(false)
      window.location.href = "dash_board/domains";
    } 
    else if (response && (response.status === RESPONSE_CODES.SERVER_ERROR)){
      // setErrMsg('Internal Server Error')
      swal({
        title:"Error!",
        text:"Internal Server Error",
        icon:"warning",
        button:"Ok"
      });
      setLoader(false)
      console.log("5XX", response.status === RESPONSE_CODES.SERVER_ERROR)
    }
    else if (response && (response.status === RESPONSE_CODES.UNAUTHORIZED)){
      // setErrMsg("unAuthorized")
      swal({
        title:"Error!",
        text:"unAuthorized",
        icon:"warning",
        button:"Ok"
      });
      setLoader(false)
      console.log("unAuthorized", response.status === RESPONSE_CODES.UNAUTHORIZED)
    }
    else if (response && (response.status === RESPONSE_CODES.NOT_FOUND)){
      // setErrMsg("Not Found")
      swal({
        title:"Error!",
        text:"Not Found",
        icon:"warning",
        button:"Ok"
      });
      setLoader(false)
      console.log("notfound", response.status === RESPONSE_CODES.NOT_FOUND)
    }
    else {
      //  setErrMsg(response.data.user.non_field_errors[0])
      swal({
        title:"Error!",
        text:response.data.user.non_field_errors[0],
        icon:"warning",
        button:"Ok"
      });
       setLoader(false)
       setDisabled(false)
    }
  };
  return (
    <>
    {/* {success ? (<Onboarding/>):( */}
     <div className="log_in_main h-screen">
     {/* <p ref={errRef} aria-live="asertive" className=" z-10 max-h-16 absolute bg-lime-300 self-center text-gray-900 font-sans  text-base  text-center rounded-md w-96 align-middle font-semibold  tracking-widest">{successMsg}</p> */}
     <div className="log_in_input">
       <div className="log_in_left_heading">
         <div className="log_in_heading">
           <p className="log_in">Sign in to </p>
           <p className="log_in">Truviss by Acviss</p>
           <p className="log_in_tag">
             Stop impoersonation, and brand abuse today.
           </p>
         </div>
         <div className="log_in_head_left">
           <img src={log} alt="" className="log_in_left" />
         </div>
       </div>
       <div className="log_in_input_right">
         <div className="log_in_input_heading">
           <p className="log_input_text">Welcome to Truviss</p>
           <p className="log_input_tag">Enter the details to gain access</p>
         </div>
         <p ref={errRef} aria-live="asertive" className="bg-red-100 self-center  text-red-600 font-sans  text-base  text-center rounded-md w-96 align-middle font-semibold  tracking-widest">{errMsg}</p>
         <p ref={errRef} aria-live="asertive" className="bg-lime-300 self-center text-cyan-900 font-sans  text-base  text-center rounded-md w-96 align-middle font-semibold  tracking-widest">{successMsg}</p> 
         {/* {error && <div className="error">{error}</div>} */}
         <div className="log_input">
           <form className="log_input" onSubmit={handleClickValidation}>
             <TextField
               required
               id="email_address"
               name="email_address"
               //value={signInData?.user_name}
               value={username}
               ref={userRef}
               label="Username"
               //type="email"
               variant="outlined"
               //onChange={handleChangeInput}
               onChange={(e) => setName(e.target.value)}
             />
             {/* <InputLabel className="validation_error">
               {inputValidation.username}
             </InputLabel> */}
             <FormControl variant="outlined">
               <InputLabel
                 htmlFor="outlined-adornment-password"
                 required={true}
               >
                 Password
               </InputLabel>
               <OutlinedInput
                 id="outlined-adornment-password"
                 name="password"
                 type="password"
                 //value={signInData.password}
                 value={password}
                 onChange={(e) =>
                  setPassword(e.target.value)
                  }
                 //onChange={handleChange('password')}
                 label="Password"
                //  endAdornment={
                //    <InputAdornment position="end">
                //      <IconButton
                //        aria-label="toggle password visibility"
                //        onClick={handleClickShowPassword}
                //        onMouseDown={handleMouseDownPassword}
                //        edge="end"
                //      >
                //        {password.showPassword ? (
                //          <VisibilityOff />
                //        ) : (
                //          <Visibility />
                //        )}
                //      </IconButton>
                //    </InputAdornment>
                //  }
               />
             </FormControl>
             {/* <InputLabel className="validation_error">
               {inputValidation.password}
             </InputLabel> */}
             <p className="log_in_forgot">Forgot Password?</p>
             {loader == true && <div  className="items-end justify-around pt-5 gap-5"><CircularProgress /></div>}
             <Box className="log_in_btn_box py-0">
               <Button
                 variant="contained"
                 className="log_signin_btn"
                // disabled={   username === "" || password === "" ? true : false}
                // disabled={disabled}
                onClick={() => (handleClickValidation(),
                  setDisabled(!disabled)
                  )}
               >
                 SIGN IN
                 
               </Button>
             </Box>
           </form>
           <p className="log_in_bottom">
             Don't have an account?&nbsp;
             <Link to="/signup"><span >Sign Up</span></Link>
           </p>
           <p className="log_in_bottom1">
             <span className="span">Having trouble signing in?</span>
           </p>
           <p className="log_in_bottom">
             Get Help, contact us on <span>infinite@acviss.com</span>
           </p>
         </div>
       </div>
     </div>
     <p className="log_in_terms">
       By using our services and website you agree with our{" "}
       <span><a href='https://acviss.com/terms-conditions/'>Terms and Conditions</a></span> & <span><a href='https://acviss.com/privacy-policy/'>Privacy Policy</a></span>
     </p>
   </div>
   {signup && (
    <Signup/>
   )} 
    {/* )} */}
    </>
  );
};
export default Index;
