import React, { useEffect, useState } from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom'
import Add_Product from "./Add_Products"
import Your_Products from "./Your_Product"
import Scan_Cycle from "./Scan_Cycle"
import { useDispatch, useSelector } from 'react-redux';
import { getAllProductConfiq } from '../../../../Redux/reducers/setUpSlice';


const Index = ({props}) => {
      const [addproduct, setAddProduct] = useState(true)
      const [yourproduct, setYourProduct] = useState("")
      const [scanCycle, setScanCycle] = useState("")
 const previous = () =>{
      window.history.back()
 }; 
  const allProductConfiq = useSelector((state) => state.confiqData.allProductConfiq)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllProductConfiq())

  }, [])
  return (
    <div className='flex flex-col gap-10  p-5 overflow-y-auto bg-gray-50'>
      <div className='flex gap-4  overflow-y-auto'>
             <KeyboardBackspaceIcon className="cursor-pointer" onClick={() =>previous()}/>
            <p>Fake Product Scanner</p>
      </div>
      <div className="flex  gap-1 border-b h-10 text-neutral-500">
   
   <p className= {`summary  ${addproduct && 'text'}`}  onClick={()=>(
     setAddProduct(true),
     setYourProduct(false),
     setScanCycle(false)
     )}>Add Products</p>
     
   <p className={`summary  ${yourproduct && 'text'}`}  onClick={()=>(
       setAddProduct(false),
       setYourProduct(true),
       setScanCycle(false)
   )} >Your Products</p>
    <p className={`summary  ${scanCycle && 'text'}`}  onClick={()=>(
        setAddProduct(false),
        setYourProduct(false),
        setScanCycle(true)
   )} >Scan Cycle</p>
 </div> 
 {addproduct && 
   <Add_Product props={props}/>
 }
 {yourproduct && 
   <Your_Products props={props}/>
 }
 {scanCycle && 
   <Scan_Cycle/>
 }
 </div>
  )
}

export default Index;