import { configureStore } from '@reduxjs/toolkit'
import dashboardSlice from './reducers/dashboardSlice';
import setUpSlice from './reducers/setUpSlice';


const store = configureStore({
    reducer: {
        dahsboardData: dashboardSlice,
        confiqData: setUpSlice,
    }
})
export default store;