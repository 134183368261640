import React, { useState,useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import {Chart} from 'chart.js/auto'
import getAllData from '../subtab_Card/getAllData';
import getAPPSData from '../subtab_Card/getAPPSData'; 
import { useLocation } from 'react-router-dom';
import getAllProducts from './getAllProducts';
import getAllsocial from './getAllsocial';
import { Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
const Index = () => {
  
  // const labels = ['Overall','Last Month','Last Week']
  const labels = ['Overall'/* ,'Last Month','Last Week' */]

  



const options ={
  plugins:{
    legend:{
      position:'top'
    },
    title:{
      display:true,
      text:"Threats identified over the time",
      size:"25px",
    },
    scales:{
     yAxes:[
        {
            ticks:{
                min:0,
                max:10,
                stepSize:2,
            }
        }
     ]
    }
  }
}
const [allData, setAllData] = useState(0)
const [allCritical, setCriticalData] = useState(0)
const [allHigh, setHighData] = useState(0)
const [allMedium, setMediumData] = useState(0)
const [allLow, setLowData] = useState(0)
const [loader, setLoader] = useState(false);
const [show, setShow] = useState(false);
const [dataPieshow, setdataPieshow] = useState(false);
const [values, setValues] = useState([]);
const [dataPie, setdataPie] = useState([]);
const location = useLocation(); 
  const domainData = useSelector((state) => state.dahsboardData.allDomainData)
  const socialMediaData = useSelector((state) => state.dahsboardData.allSocialMediaData)
  const allProductData = useSelector((state) => state.dahsboardData.allProductData)
  const allAppData = useSelector((state) => state.dahsboardData.allAppData)
  const requiredData = location.pathname === "/dash_board/domains" ? domainData : location.pathname === "/dash_board/apps" ? allAppData : location.pathname === "/dash_board/products" ? allProductData : socialMediaData
const getData = async () => {


  if (requiredData) {
    //console.log(response)
    setLoader(false);
  
   
  const vals = {
    labels,
    datasets: [
      {
        label:'Total',
        data: [requiredData.count ?? 0],
        backgroundColor:'#e2cc15',
        pointBackgroundColor:'#e2cc15'
      },
      {
        label:'Critical',
        data: [requiredData.critical_count ?? 0],
        backgroundColor:'#007afe',
        pointBackgroundColor:'#6b6c70'
      },
      {
        label:'High',
        data: [requiredData.high_count ?? 0],
        backgroundColor:'#34aa44'
      },
      {
        label:'Medium',
        data: [requiredData.medium_count ?? 0],
        backgroundColor:'#6f42c1'
      },
      {
        label:'Low',
        data: [requiredData.low_count ?? 0],
        backgroundColor:'#e83e8c'
      }
    ]
  }
  if(vals){
    setValues(vals)
    setShow(true) 
  }
  const dataPie = {
    labels: ['Total','Critical','High','Medium','Low'],
    datasets: [
      {
        label: '# of Threat type',
        data: [
          requiredData.count ?? 0,
          requiredData.critical_count ?? 0,
          requiredData.high_count ?? 0,
          requiredData.medium_count ?? 0,
          requiredData.low_count ?? 0
        ],
        backgroundColor: [
          '#e2cc15',
          '#007afe',
          '#34aa44',
          '#6f42c1',
          '#e83e8c', 
        ],
        borderColor: [
          '#e2cc15',
          '#007afe',
          '#34aa44',
          '#6f42c1',
          '#e83e8c', 
        ],
        borderWidth: 1,
      },
    ],
  }
  if(dataPie){
    setdataPie(dataPie)
    setdataPieshow(true) 
  }
}
}
useEffect(()=>{
  if (requiredData !== null) {
    getData(); 
  }
  else { 
    setAllData(0); 
    setCriticalData(0); 
    setHighData(0); 
    setMediumData(0); 
    setLowData(0);  
    const vals = {
      labels,
      datasets: [
        {
          label:'Critical',
          data:[0],
          backgroundColor:'#007afe',
          pointBackgroundColor:'#6b6c70'
        },
        {
          label:'High',
          data:[0],
          backgroundColor:'#34aa44'
        },
        {
          label:'Medium',
          data:[0],
          backgroundColor:'#6f42c1'
        },
        {
          label:'Low',
          data:[0],
          backgroundColor:'#e83e8c'
        }
      ]
    }
    if(vals){
      setValues(vals)
      setShow(true) 
    }
    const dataPie = {
      labels: ['Total','Critical','High','Medium','Low'],
      datasets: [
        {
          label: '# of Threat type',
          data: [
            0,
            0,
            0,
            0,
            0
          ],
          backgroundColor: [
            '#e2cc15',
            '#007afe',
            '#34aa44',
            '#6f42c1',
            '#e83e8c', 
          ],
          borderColor: [
            '#e2cc15',
            '#007afe',
            '#34aa44',
            '#6f42c1',
            '#e83e8c', 
          ],
          borderWidth: 1,
        },
      ],
    }
    if(dataPie){
      setdataPie(dataPie)
      setdataPieshow(true) 
    }
  } 
}, [requiredData]) 
 
return (
      <>
        <div className="flex gap-5 piechart_images ">
        <div className="w-500">
           {show == true &&
          <Bar options={options} data={values} className="bar_charts" height={600} />
           } 
        </div>  
        <div className="w-500">
           {dataPieshow == true &&
          <Pie data={dataPie} className='pieChart' height={600} /> 
           } 
        </div>  
        </div>  
        </>
  )
}

export default Index;