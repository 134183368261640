import React , {useEffect,useState}from 'react'
import MUITable from '../../../../Common/MUITable'
import style from '../../../../Common/MUITable'
import {Button,CircularProgress} from '@mui/material';
import getAllProducts from './getAllProducts';
import Product_Details from './Product_Details'
import Pagination from '../../../../Common/Pagination'
import nextForm from '../Scan_Cycle/nextForm';
import prevForm from '../Scan_Cycle/prevForm';
import { useSelector } from 'react-redux';
const Index = () => {
  const [isDetails, setDetails] = useState(false)
  const data = [
        
  ]     
   const [allProducts, setAllProducts] = useState([])
   const {nextData} = nextForm();
   const {prevdata} = prevForm();
   const [loader, setLoader] = useState(false);
   const [nextLink, setNextApp] = useState('');
   const [previousLink, setPreviousApp] = useState('');
  const allProductConfiq = useSelector((state) => state.confiqData.allProductConfiq)
  const getData = async () => {
    if (allProductConfiq.results.length > 0) {
      allProductConfiq.results.forEach(function (singleProduct) { 
        data.push({
          product: singleProduct.product_name,
          price_range: '₹ '+singleProduct.min_price+' - '+singleProduct.max_price,
          // market_places: "",
          status: "Verified",
          details: "Test Corp",
        }); 
      }); 
      if (allProductConfiq.results.length > 0) {  
        setAllProducts(data)
        if (allProductConfiq.next_page) {
          setNextApp(allProductConfiq.next_page); 
        }else{
          setNextApp(); 
        } 
        if (allProductConfiq.prev_page) {
          setPreviousApp(allProductConfiq.prev_page); 
        }else{
          setPreviousApp(); 
        }  
      }
    }
  } 

  const next = async () =>{ 
    setAllProducts([]); 
    setLoader(true);
    const response = await nextData(nextLink);  
    if (response.results.length > 0) {
      response.results.forEach(function (singleProduct) { 
        data.push({
          product: singleProduct.product_name,
          price_range: '₹ '+singleProduct.min_price+' - '+singleProduct.max_price,
          // market_places: "",
          status: "Verified",
          details: "Test Corp",
        }); 
      }); 
      setAllProducts(data)
      if (response.next_page) {
        setNextApp(response.next_page); 
      }else{
        setNextApp(); 
      } 
      if (response.prev_page) {
        setPreviousApp(response.prev_page); 
      }else{
        setPreviousApp(); 
      }  
    }
    setLoader(false);
  }
  const previous = async () =>{ 
    setAllProducts([]); 
    setLoader(true);
    const response = await prevdata(previousLink);
    if (response.results.length > 0) {
      response.results.forEach(function (singleProduct) { 
        data.push({
          product: singleProduct.product_name,
          price_range: '₹ '+singleProduct.min_price+' - '+singleProduct.max_price,
          // market_places: "",
          status: "Verified",
          details: "Test Corp",
        }); 
      }); 
      setAllProducts(data)
      if (response.next_page) {
        setNextApp(response.next_page); 
      }else{
        setNextApp(); 
      } 
      if (response.prev_page) {
        setPreviousApp(response.prev_page); 
      }else{
        setPreviousApp(); 
      }  
    }
    setLoader(false);
  }

  useEffect(()=>{
    getData();
  }, [allProductConfiq]) 


 const options = {
            filter: false,
            filterType: "dropdown",
            responsive: "vertical",
            selectableRows: "none",
            download: false,
            print: false,
            sort: false,
            viewColumns: false,
            search: false,
            textLabels: {
              body: {
                noMatch: (
                  <div className="no_data_found">
                    {/* <img src={NodataFound} alt="No data Found" /> */}
                    <p>No data Found...</p>
                    {/* <CircularProgress/> */}
                  </div>
                ),
              },
            },
          };
      const columns = [
            {
                  name: "product",
                  label: "Product Name",
                },
                {
                  name: "price_range",
                  label: "Price Range",
                },
               /*  {
                  name: "market_places",
                  label: "Market Places",
                }, */
                {
                  name: "status",
                  label: "Status",
                },
                {
                  name: "details",
                  label: "Details",
                  options: {
                    customBodyRender: (value) => {
                      return (
                        <div>
                            <Button  variant="contained" onClick={() =>(setDetails(true))} style={{textTransform: 'none'}} sx={{ mt: 1, mr: 1 }}>
                                  Details..
                             </Button>
                        </div>
                      );
                    },
                  },
                },
          ]          
        
  return (
    <div>
        <MUITable
        columns={columns}
        table={allProducts}
        //table={tableData?.length ? tableData : []}
        options={options}
      />
      {/* <Pagination
      PagePerRow={10}
    /> */}
        {isDetails && <Product_Details closePopUp={setDetails} userData={allProducts} /> }
        {loader == true && <div  className="flex items-end justify-around pt-5 gap-5"><CircularProgress /></div>}
        <div className='d-flex' style={{marginTop: '10px'}} >
        {previousLink && <Button variant="contained"   style={{textTransform: 'none'}} onClick={ () => previous()}>Previous</Button>} &nbsp;
        {nextLink && <Button variant="contained"   style={{textTransform: 'none'}} onClick={ () => next()}>Next</Button>}
        </div>
    </div>
  )
}
export default Index