import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TabTitle } from '../../../Config/generalFunctions'
import { getAllSocialMediaData } from '../../../Redux/reducers/dashboardSlice'
import Sub_tab from '../../Components/Sub_tab'

const Index = () => {
  TabTitle('Social Media | Truviss ')
  const allSocialMediaData = useSelector((state) => state.dahsboardData.allSocialMediaData)
  const dispatch = useDispatch()
  useEffect(() => {
    if (allSocialMediaData === null) {
      dispatch(getAllSocialMediaData())
    }
  }, [allSocialMediaData])
  return (
    <div className='p-5 h-278 bg-gray-50 overflow-y-scroll'>
      <Sub_tab/>
    </div>
  )
}
export default Index;
