import React, { useState,useEffect } from "react";
import {
    TextField,
    Autocomplete,
    Card,
    CardContent,
    Typography,
  } from "@mui/material";
import Bar_Charts from '../Bar_Charts'
import Common_Card from '../subtab_Card' 
import { TabTitle } from '../../../Config/generalFunctions';
import { useLocation } from 'react-router-dom';
const Index = () => {
  const location = useLocation(); 
  const [setApp, setApps] = useState(false);
  const [setProduct, setProducts] = useState(false);
  const [setDomain, setDomains] = useState(false);
  const [setSoc, setSocial] = useState(false);

  useEffect(()=>{ 
  },[]) 


  TabTitle('Summary | Truviss')
    const dropdown = ["Last 7 days", "Last 30 days","Last year"];
    const dropdown1 = ["All","aSd"];
  return (
    <div className="flex flex-col gap-6 ">
        <div className="flex justify-start   pt-5 gap-5 flex-wrap">
          <Autocomplete
            id="combo-box-demo"
            options={dropdown1}
            sx={{ width: 200 }}
            renderInput={(params) => <TextField {...params} label="Asset" />}
          />
          <Autocomplete
            id="combo-box-demo"
            options={dropdown}
            sx={{ width: 200 }}
            renderInput={(params) => <TextField {...params} label="Range" />}
          />
        </div>  
        <Common_Card/>
          
      <Bar_Charts />    
      </div>
  )
}

export default Index;