import API_END_POINTS from '../../../Config/integrations';
import { getApiResponseObject } from '../restApisFunctions';
//pushedagain
const getAPPSData = () => {
  const appsdata = async (e) => {
    const response = await getApiResponseObject(
      API_END_POINTS.appResultsGet,
      JSON.stringify(),
    );
    return response;
  };
  return {
    appsdata
  };
};

export default getAPPSData;