import API_END_POINTS from '../../../../../Config/integrations';
import { postWithResponseObject } from '../../../../Common/restApisFunctions';

const signupAppService = () => {
  const signupApps = async (appname,device) => { 
    let item =  { 
      "app_name": appname,
      "device": device
    }; 
    const response = await postWithResponseObject(
      API_END_POINTS.appConfigPost,
      JSON.stringify(item),
    ); 
    return response;
  };
  return {
      signupApps
  };
};

export default signupAppService;