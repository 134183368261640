import React, { useState } from "react";
import { TextField, InputLabel, Box, Button,Autocomplete } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import swal from 'sweetalert';

const Index = () => {
      const dropdown = ['Admin', 'Maintainer','Viewer'];
  const [userData, setUserData] = useState({
    user_name: "",
    email: "",
    phone_number: "",
    role:"",
  });
  const [inputValidation, setInputValidation] = useState({
    user_name: "",
    email: "",
    phone_number:"",
    role:"",
  });
  const [role, setRole] = useState('')
  const handleChangeInput = (event) => {
    event.preventDefault();
    setUserData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    setInputValidation((prevState) => ({
      ...prevState,
      [event.target.name]: "",
    }));
  };
  const handleMobileChangeInput = (event) => {
    setUserData((prevState) => ({
      ...prevState,
      phone_number: event,
    }));
    setInputValidation("");
  };
  const handleSubmit = async (event) => {
    var errorHandle = false;
    if (!userData?.user_name) {
      document.getElementById("user_name")?.focus();
      setInputValidation((prevState) => ({
        ...prevState,
        user_name: "This field is required.",
      }));
      errorHandle = true;
    }
    if (!userData?.email) {
      document.getElementById("email")?.focus();
      setInputValidation((prevState) => ({
        ...prevState,
        email: "This field is required.",
      }));
      errorHandle = true;
    }
    if (!userData?.phone_number) {
      document.getElementById("phone_number")?.focus();
      setInputValidation((prevState) => ({
        ...prevState,
        phone_number: "This field is required.",
      }));
      errorHandle = true;
    }
    if (!userData?.role) {
      document.getElementById("role")?.focus();
      setInputValidation((prevState) => ({
        ...prevState,
        role: "This field is required.",
      }));
      errorHandle = true;
    }
    if(!errorHandle){  
      swal({
        title:"User",
        text:"Added Successfully",
        icon:"success",
        button:"ok",
      })
    } 
  };
  return (
    <div >
      <form className="flex flex-col gap-6" >
        <div>
          <TextField
            required={true}
            id="user_name"
            name="user_name"
            label="Name"
            variant="outlined"
            value={userData?.user_name}
           fullWidth
            onChange={handleChangeInput}
          />
          <InputLabel>
            <p className="text-orange-600 text-sm">
              {inputValidation?.user_name}
            </p>
          </InputLabel>
        </div>
        <div>
          <TextField
            required={true}
            id="email"
            name="email"
            label="E-Mail"
            variant="outlined"
            value={userData?.email}
            fullWidth
            onChange={handleChangeInput}
          />
          <InputLabel>
            <p className="text-orange-600 text-sm">{inputValidation?.email}</p>
          </InputLabel>
        </div>
        <div>
          <MuiPhoneNumber
            defaultCountry={"in"}
            //onlyCountries={"cu","cw","kz"}
            id="phone_number"
            name="phone_number"
            variant="outlined"
            label="Phone"
            required={true}
            fullWidth
            //countryCodeEditable={false}
            value={userData?.phone_number}
            onChange={(e) => {
              handleMobileChangeInput(e);
              setInputValidation((prevState) => ({
                ...prevState,
                phone_number: "",
              }));
            }}
          />
          <InputLabel>
            <p className="text-orange-600 text-sm">
              {inputValidation?.phone_number}
            </p>
          </InputLabel>
        </div>
        <div className="ml-5">
          <ul className="list-disc">
            <li>
              Admin
              <span className="text-neutral-500 font-sans text-sm">
                - Having full access control.
              </span>
            </li>
            <li>
              Maintainer
              <span className="text-neutral-500 font-sans text-sm">
                - Having full access control but cannot manage IAM (Identity and
                Access Management)
              </span>
            </li>
            <li>
              Viewer
              <span className="text-neutral-500 font-sans text-sm">
                - Having only read access control and cannot manage IAM
                (Identity and Access Management)
              </span>
            </li>
          </ul>
        </div>
        <div>
          <Autocomplete
            disablePortal
            fullWidth
            options={dropdown}
            name="role"
            value={userData?.role}
            onChange={(event, newValue) => {
              setUserData((prevState) => ({
                ...prevState,
                role: newValue,
              }));
              setInputValidation((prevState) => ({
                role: "",
              }));
            }}
            
            renderInput={(params) => <TextField {...params} label="Role" />}
          />
           <InputLabel>
            <p className="text-orange-600 text-sm">
              {inputValidation?.role}
            </p>
          </InputLabel>
        </div>
        <Box>
          <Button
            variant="contained"
            className="capitalize"
            onClick={handleSubmit}
          >
            Create User
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default Index;
