import React,{useEffect} from 'react'
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import {useNavigate} from "react-router-dom"
import { logOut } from '../../../Common/authUtils';
const Index = () => {
  const history = useNavigate();
  return (
    <div className='card right-5  p-2 '>
      <div className='card_items'>
        <LockIcon/>
          <p>Change Password</p>
      </div>
      <hr/>
      
      <div className='card_items' onClick={()=> logOut(history)}>
      <LogoutIcon/>
     <p >Log out</p>
      </div>
    </div>
  )
}

export default Index;