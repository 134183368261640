import React from 'react'
//import './style.scss'
import { useNavigate } from "react-router-dom";
const Index = () => { 
  let navigate = useNavigate();
  const selectmenu = (value) => { 
    navigate(`/dash_board/${value}`); 
  };
  return (
    <div className='card right-8 w-80 p-2 z-10'>
       <div className='flex justify-between'>
         <p className='text-black'>Notifications</p>
         <p className='text-primary underline text-sm'>Clear All</p>
       </div>
       <div className='h-10 overflow-y-scroll'>
         <p className='text-black'>No recent notifications available </p>
       </div>
       <div className='notification_footer'>
        <a  href='#' onClick={() => selectmenu('notifications')} className=' underline block text-center hover:bg-secondary'>Show all notifications</a>
       </div>
        </div>
  )
}

export default Index;