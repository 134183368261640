import React, { useState,useEffect } from "react";
import { Box, Button, FormControlLabel, Checkbox, CircularProgress } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { TextField, Autocomplete } from "@mui/material";
import { TabTitle } from "../../../../../Config/generalFunctions";
import {RESPONSE_CODES} from '../../../../../Config/definations'
import swal from 'sweetalert';
import getAllProducts from '../Your_Product/getAllProducts';
import getProductScan from './service'
import nextForm from './nextForm';
import prevForm from './prevForm';
import { useSelector } from "react-redux";
const Index = () => {
  TabTitle('Fake Domain Scanner Service')
  const dropdown = ["Day", "Week"];
  const dropdown1 = ["Immediately", "Next Schedule"];
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  const [toggle, setToggle] = useState(false);
  const [scanitems, setCheckbox] = useState([]);
  const [loader, setLoader] = useState(false);
  const {scanPro} = getProductScan();
  const [nextLink, setNextApp] = useState('');
  const [previousLink, setPreviousApp] = useState('');
  const [scanData, setScanData] = useState({
    days : "",
    week : "",
    scan : ""
  })
  const {nextData} = nextForm();
  const {prevdata} = prevForm();
  

  const handleChangeCheck = (item) => {

    setCheckbox(scanitems.includes(item) ? scanitems.filter(e => e !== item) : [...scanitems, item]);

  };

  const allProductConfiq = useSelector((state) => state.confiqData.allProductConfiq)

   const [allProducts, setAllProducts] = useState([])
   const getData = async () => {
    setLoader(true);
     //console.log(response)
     if (allProductConfiq) {
      setLoader(false);
    }
     if (allProductConfiq.results.length > 0) {
       setAllProducts(allProductConfiq.results)
       if (allProductConfiq.next_page) {
         setNextApp(allProductConfiq.next_page); 
      }else{
        setNextApp(); 
      } 
       if (allProductConfiq.prev_page) {
         setPreviousApp(allProductConfiq.prev_page); 
      }else{
        setPreviousApp(); 
      }  
    }
  }
  
  const next = async () =>{ 
    setAllProducts([]); 
    setLoader(true);
    const response = await nextData(nextLink);  
    if (response.results.length > 0) {
      setAllProducts([...response.results]);
      if (response.next_page) {
        setNextApp(response.next_page); 
      }else{
        setNextApp(); 
      } 
      if (response.prev_page) {
        setPreviousApp(response.prev_page); 
      }else{
        setPreviousApp(); 
      }  
    }
    setLoader(false);
  }
  const previous = async () =>{ 
    setAllProducts([]); 
    setLoader(true);
    const response = await prevdata(previousLink);
    if (response.results.length > 0) {
      setAllProducts([...response.results]);
      if (response.next_page) {
        setNextApp(response.next_page); 
      }else{
        setNextApp(); 
      } 
      if (response.prev_page) {
        setPreviousApp(response.prev_page); 
      }else{
        setPreviousApp(); 
      }  
    }
    setLoader(false);
  }

  
  useEffect(()=>{
    getData();
  },[]) 

const handleChange = (e) =>{
  setScanData((prevState) => ({
    ...prevState,
    [e.target.name]: e.target.value,
  }));
}

const scanProducts = async () =>{ 
  if(scanitems.length > 0){
    for(var i=0;i<scanitems.length;i++){
      var id = scanitems[i];
      const response = await scanPro(id); 
      if(i === scanitems.length -1){ 
        let status = response.status; 
        if (status == 201) {
          swal({
            title:"Scan Cycle",
            text:"Started Successfully",
            icon:"success",
            button:"ok",  
          }).then(()=>{
            setCheckbox([])
          });
        }else {
          swal({
              title:response.status,
              text:"Something went wrong, try again later ...",
              icon:"error", 
              button:"ok",  
            })
        }
      }
    }
  }else{
    swal({
      title:"Please select at least one",
      text:"Before starting scan",
      icon:"error", 
      button:"ok",  
    })
  } 
  
}


// const scanDomainget= async () =>{
//     //const new_domain = scanData.replace(/https?:\/\/(?:www\.)?/, "").append(".");
//     // const data = await scanDomain()
//     // console.log("%%%%%%%",data)
//     // .then((res) => console.log(res))
//     // .catch((err) => console.log(err))
//     const response = await scanDomain();
//     console.log("Responsezsrc4zcr",response)
//     let error = response.status;
//     console.log("Error",error)
//     if (response && (response.status === RESPONSE_CODES.ERROR)) {
//          alert('you missed the required fields')
//      } 
//      else {
//        swal({
//              title:"Scan Cycle Configuration",
//              text:"Are you sure want to setup this scan cycle ?",
//              //icon:"success",
//              button:"Yes",  
//             })
//      }
// }


  return (
    <div className="flex justify-between">
      <div className="flex flex-col  gap-3   p-4  mt-2  bg-white rounded-3xl shadow-md overflow-hidden w-2/4">
        {allProducts.length > 0 ? (Object.entries(allProducts).map((val, key) => {
          return (  
            <div key={key} className='flex  items-center   pt-5 gap-5'>
              <FormControlLabel key={key} control={<Checkbox value={val[1].id} name={val[1].product_name} checked={scanitems.includes(val[1].id)} onChange={(e) => { handleChangeCheck(val[1].id) }} />} label="" />
             <h3>{val[1].product_name ?? ''} -: {'₹ '+val[1].min_price ?? ''} - {val[1].max_price ?? ''}</h3>
             </div>
            
          )})):'Loading ...'}
           {loader == true && <div  className="flex items-end justify-around pt-5 gap-5"><CircularProgress /></div>}
           <div className='d-flex'>
           {previousLink && <Button variant="contained"  style={{textTransform: 'none'}} onClick={ () => previous()}>Previous</Button>} &nbsp;
           {nextLink && <Button variant="contained"  style={{textTransform: 'none'}} onClick={ () => next()}>Next</Button>}
           </div>
       {/*  <div className="flex items-center gap-2">
          <p className="text-neutral-600">scan enabled</p>
          <Switch {...label} onClick={() => setToggle(!toggle)}/>
        </div>
        {toggle && 
        <div className="flex items-center flex-col gap-5">
        <div className="flex items-center ">
          <p className="text-neutral-600">Repeat scan every</p>
          <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
            <OutlinedInput
              aria-describedby="outlined-weight-helper-text"
             value={1}
             name="Day"
              //value={scanData?.days}
              onChange={(e) => handleChange(e)}
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </FormControl>
          <Autocomplete
            fullWidth
            name='week'
            //value={week}
            onChange={(e) => handleChange(e)}
            options={dropdown}
            sx={{ width: 200 }}
            value={dropdown?.[0]}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </div>
            <Autocomplete
            fullWidth
            name="scan"
            value={scanData?.scan}
            onChange={(e) => handleChange(e)}
            options = {dropdown1}
            sx={{ width: 665 }}
            renderInput={(params) => (
              <TextField {...params} label="startscan"/>
            )}
          />
      </div>
        } */}
        <div className='d-flex'>
          <Button variant="contained" className="capitalize "  onClick={() => scanProducts()}>
            Start Scan
          </Button>
        </div>
      </div>
      <div className="flex flex-col  gap-3 mx-4 p-4  mt-2  bg-white rounded-3xl shadow-md overflow-hidden w-2/4">
        <h1>Help?</h1>
        <p className="text-neutral-400">Fake domain scanner</p>
        <div className="flex flex-col gap-2">
          <p className="domainscan_guide">
            Click on “Add a domain” and enter the domain name in the text box.
          </p>
          <p className="domainscan_guide">
            Step 2: Click on “Verify” and enter a company email address you have
            access to that belongs to the same domain.
          </p>
          <p className="domainscan_guide">
            Step 3: Click on “Verify email” and a verification request will be
            sent to Truviss customer support.
          </p>
          <p className="domainscan_guide">
            Step 4: Once the email is verified you can proceed to setup the Scan
            cycle..
          </p>
          <p className="domainscan_guide">Step 6: Setup the Scan cycle.</p>
        </div>
      </div>
    </div>
  );
};

export default Index;
