import React, { useState,useEffect } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { TextField, Autocomplete,CircularProgress,MenuItem,Select,Box,Checkbox,FormControlLabel,Button} from "@mui/material";
import MUITable from "../MUITable"; 
// import { columns, data, options } from "./Json_data";
import { useLocation } from 'react-router-dom';
import Filter from '../Filter'
import { TabTitle } from '../../../Config/generalFunctions';
import Pagination from '../../Common/Pagination'
import getAllData from './getAllData';
import nextForm from './nextForm';
import prevForm from './prevForm'; 
import getAPPSData from '../subtab_Card/getAPPSData';
import getproductsdata from './getproductsdata';
import getAllsocial from './getAllsocial';
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
const Index = () => {
  TabTitle(' Defend | Truviss ')
  const location = useLocation();
  const [filter, setFilter] = useState(false);
  // const input = ["new 1", "new 2"];
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false); 
  const [open2, setOpen2] = useState(false); 
  const [open3, setOpen3] = useState(false); 
  const [openAssign, setAssignOpen] = useState(false); 
  const [itemDropdown, setitemDropdown] = useState([])
  const [datanGet, setAlldatanGet] = useState([])
  const [showing, setShowing] = useState([])
  const [datanApp, setAPPdatanGet] = useState([])
  const [datanProduct, setAllProductGet] = useState([])
  const [allTotal, setAllTotal] = useState([])
  const [dataModal, setModalData] = useState([])
  const [dataModal2, setModalData2] = useState([])
  const [dataModal3, setModalData3] = useState([])
  const [dataAssignModal, setAssignData] = useState([])
  const [datanSocial, setAllSocialGet] = useState([])
  const [nextLink, setNextDomain] = useState('');
  const [previousLink, setPreviousDomain] = useState('');
  const {nextData} = nextForm();
  const {prevdata} = prevForm();
  const {appsdata} = getAPPSData();
  const {productsdata} = getproductsdata();
  const {social} = getAllsocial();
  const [checked, setChecked] = React.useState([true, false]);
  const [scanitems, setCheckbox] = useState([]);
  const [setOpenTwo, setOpenChecked] = useState(false);
  const [prevState, setprevState] = useState(false);
  const [checkedSingle, setcheckedSingle] = useState('');
  const domainData = useSelector((state) => state.dahsboardData.allDomainData)
  const socialMediaData = useSelector((state) => state.dahsboardData.allSocialMediaData)
  const allProductData = useSelector((state) => state.dahsboardData.allProductData)
  const allAppData = useSelector((state) => state.dahsboardData.allAppData)
  const requiredData = location.pathname === "/dash_board/domains" ? domainData : location.pathname === "/dash_board/apps" ? allAppData : location.pathname === "/dash_board/products" ? allProductData : socialMediaData
  const handleChange = (event) => {
    setChecked([event.target.checked, event.target.checked,event.target.checked,event.target.checked]);
  };
  const handleChangeThreat = (event) => {
    if(event.target.checked){
      setOpenChecked(true);
      setcheckedSingle(event.target.checked)
    }else{
      setOpenChecked('')
      setcheckedSingle(false);
    }
  }; 
  const columns = [
    { name: "threat", label: "Threat Type" },
    { name: "domain", label: "Asset" },
    { name: "risk_score", label: "Risk score" }, 
    { name: "action", label: "Action" },  
    // { name: "screenshot", label: "Image" },  
    {
      name: "screenshot",
      label: "Image",
      options: {
        customBodyRender: () => {
          return (
            <></>
          )
        }
      }
    }
  ];
  const columns2 = [
    { name: "hash", label: "#" },
    { name: "app_name", label: "App Name" },
    { name: "description", label: "Description" },
    { name: "url", label: "Link" }, 
  ];
 
  const options = {
    filter: false,
    filterType: "dropdown",
    responsive: "vertical",
    // selectableRows: true,
    download: false,
    print: false,
    sort: false,
    viewColumns: false,
    search: false,
    textLabels: {
      body: {
        noMatch: (
          <div className="no_data_found">
            <p>No data Found...</p>
          </div>
        ),
      },
    },
  };

  const getData = async () => {
    setLoader(true);
    setAlldatanGet([])
    if (requiredData) { 
      setLoader(false); 
    }
    if (requiredData.results.length > 0) { 
      var datan = [];   
      for (var i = 0; i <= requiredData.results.length; i++) {
        if (requiredData.results[i] != undefined) {
          // var asset = requiredData.results[i].domain ?? '-';   
          var asset = location.pathname === "/dash_board/domains" ? requiredData.results[i].domain ?? '-' : location.pathname === "/dash_board/apps" ? requiredData.results[i].app_name ?? '-' : requiredData.results[i].product_name ?? '-'
          datan.push( asset ); 
        }
        if (i === requiredData.results.length - 1) {
          setitemDropdown(datan)
        }
      }      
      setShowing(requiredData.results.length ?? 0)
      location.pathname === "/dash_board/domains" ? setAlldatanGet(requiredData.results) : location.pathname === "/dash_board/apps" ? setAPPdatanGet(requiredData.results) : location.pathname === "/dash_board/products" ? setAllProductGet(requiredData.results) : setAllSocialGet(requiredData.results)

      setAllTotal(requiredData.count)
      if (requiredData.next_page) {
        setNextDomain(requiredData.next_page); 
      }else{
        setNextDomain(); 
      } 
      if (requiredData.prev_page) {
        setPreviousDomain(requiredData.prev_page); 
      }else{
        setPreviousDomain(); 
      } 
    }
  } 
  useEffect(()=>{
    if (requiredData === null) {
    setLoader(true)
    } else {
      getData();  
    }
  }, [requiredData]) 

  const handleOpen = async (data) => { 
    setModalData([]) 
    console.log(data)
    setModalData(data) 
    setOpen(true)
  }
  const handleOpenApps = async (data) => { 
    setModalData2([]) 
    console.log(data)
    setModalData2(data) 
    setOpen2(true)
  }
  const handleOpenSocial = async (data) => { 
    setModalData3([]) 
    console.log(data)
    setModalData3(data) 
    setOpen3(true)
  }
  const handleAssign = async (data) => { 
    setAssignData([]) 
    setAssignData(data) 
    setAssignOpen(true)
  }
  const handleCloseAssign = async () => {  
    setAssignOpen(false)
  }
  const handleClose = async () => {  
    setOpen(false)
  }
  const handleCloseOpen2 = async () => {  
    setOpen2(false)
  }
  const handleCloseOpen3 = async () => {  
    setOpen3(false)
  }

  const next = async () =>{ 
    setLoader(true);
    setAlldatanGet([])
    setAPPdatanGet([])
    setAllProductGet([])
    setAllSocialGet([])
    const response = await nextData(nextLink);  
    if (response.results.length > 0) {
      var datan = [];  
      if(location.pathname == '/dash_board/domains'){
        for (var i = 0; i <= response.results.length; i++) {
          if (response.results[i] != undefined) {
            var asset = response.results[i].domain ?? '-';   
            datan.push( asset ); 
          }
          if (i === response.results.length - 1) {
            setitemDropdown(datan)
          }
        } 
        if (response.results.length == 10) {
          setShowing(showing + 10)
        }else{
          setShowing(response.results.length)
          setprevState(true)
        }
        setAllTotal(response.count)
        setAlldatanGet(response.results)
      }else if(location.pathname == '/dash_board/apps'){
        for (var i = 0; i <= response.results.length; i++) {
          if (response.results[i] != undefined) {
            var asset = response.results[i].app_name ?? '-';   
            datan.push( asset ); 
          }
          if (i === response.results.length - 1) {
            setitemDropdown(datan)
          }
        }
        if (response.results.length == 10) {
          setShowing(showing + 10)
        }else{
          setShowing(response.results.length)
          setprevState(true)
        }
        setAllTotal(response.count)
        setAPPdatanGet(response.results)
      }else if(location.pathname == '/dash_board/products'){   
        for (var i = 0; i <= response.results.length; i++) {
          if (response.results[i] != undefined) {
            var asset = response.results[i].product_name ?? '-';   
            datan.push( asset ); 
          }
          if (i === response.results.length - 1) {
            setitemDropdown(datan)
          }
        } 
        if (response.results.length == 10) {
          setShowing(showing + 10)
        }else{
          setShowing(response.results.length)
          setprevState(true)
        }
        setAllTotal(response.count)
        setAllProductGet(response.results)
       }else if(location.pathname == '/dash_board/social_media'){   
        for (var i = 0; i <= response.results.length; i++) {
          if (response.results[i] != undefined) {
            var asset = response.results[i].product_name ?? '-';   
            datan.push( asset ); 
          }
          if (i === response.results.length - 1) {
            setitemDropdown(datan)
          }
        } 
        if (response.results.length == 10) {
          setShowing(showing + 10)
        }else{
          setShowing(response.results.length)
          setprevState(true)
        }
        setAllTotal(response.count)
        setAllSocialGet(response.results)                                                                                                             
      }
      if (response.next_page) {
        setNextDomain(response.next_page); 
      }else{
        setNextDomain(); 
      } 
      if (response.prev_page) {
        setPreviousDomain(response.prev_page); 
      }else{
        setPreviousDomain(); 
      }  
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }
  const previous = async () =>{ 
    setLoader(true);
    const response = await prevdata(previousLink);
    setAlldatanGet([])
    setAPPdatanGet([])
    setAllProductGet([])
    setAllSocialGet([])
    if (response.results.length > 0) {
      var datan = [];  
      if(location.pathname == '/dash_board/domains'){
        for (var i = 0; i <= response.results.length; i++) {
          if (response.results[i] != undefined) {
            var asset = response.results[i].domain ?? '-';   
            datan.push( asset ); 
          }
          if (i === response.results.length - 1) {
            setitemDropdown(datan)
          }
        } 
        if(prevState == true){
          setShowing(response.count - showing) 
           setprevState(false)
        }else{ 
          if (response.results.length == 10) { 
            setShowing(showing - 10) 
          }
      }
        setAlldatanGet(response.results)
        setAllTotal(response.count)
      }else if(location.pathname == '/dash_board/apps'){
        for (var i = 0; i <= response.results.length; i++) {
          if (response.results[i] != undefined) {
            var asset = response.results[i].app_name ?? '-';   
            datan.push( asset ); 
          }
          if (i === response.results.length - 1) {
            setitemDropdown(datan)
          }
        }
        if(prevState == true){
          setShowing(response.count - showing) 
          setprevState(false)
          }else{ 
          if (response.results.length == 10) { 
              setShowing(showing - 10) 
            }
        }
        setAPPdatanGet(response.results)
      }else if(location.pathname == '/dash_board/products'){   
        for (var i = 0; i <= response.results.length; i++) {
          if (response.results[i] != undefined) {
            var asset = response.results[i].product_name ?? '-';   
            datan.push( asset ); 
          }
          if (i === response.results.length - 1) {
            setitemDropdown(datan)
          }
        } 
        if(prevState == true){
          setShowing(response.count - showing) 
              setprevState(false)
          }else{ 
          if (response.results.length == 10) { 
              setShowing(showing - 10) 
            }
        }
        setAllProductGet(response.results) 
      }else if(location.pathname == '/dash_board/social_media'){   
        for (var i = 0; i <= response.results.length; i++) {
          if (response.results[i] != undefined) {
            var asset = response.results[i].product_name ?? '-';   
            datan.push( asset ); 
          }
          if (i === response.results.length - 1) {
            setitemDropdown(datan)
          }
        } 
        if(prevState == true){
          setShowing(response.count - showing) 
              setprevState(false)
          }else{ 
          if (response.results.length == 10) { 
              setShowing(showing - 10) 
            }
        }
        setAllSocialGet(response.results) 
      }
      if (response.next_page) {
        setNextDomain(response.next_page); 
      }else{
        setNextDomain(); 
      } 
      if (response.prev_page) {
        setPreviousDomain(response.prev_page); 
      }else{
        setPreviousDomain(); 
      }  
      setTimeout(() => {
        setLoader(false);
      }, 1000);
    }
  }

  const handleChangeCheck = (item) => {
    let allitems = scanitems; 
    if (allitems.some((singleItem) => singleItem === item)) { 
      allitems = allitems.filter((singleItem) =>  
      singleItem !== item
      );
    } else {
      allitems.push(item);
    } 
    setCheckbox(allitems); 
  };


  
  return (
    <div className="mui_table mt-6">
      <div className="flex flex-wrap gap-5 justify-between">
        <div className="flex gap-5 items-center">
          <FilterListIcon
            className="cursor-pointer"
            onClick={() => setFilter(!filter)}
          />
          <p>Filter</p>
        </div>

        <div className="flex flex-wrap items-center gap-2">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={itemDropdown}
            sx={{ width: 200 }}
            renderInput={(params) => <TextField {...params} label="Asset" />}
          />
          <button className="bg-primary text-teal-50 shadow-lg shadow-indigo-500/50 px-3 py-1 rounded">
            Export as CSV
          </button>
        </div>
      </div>
      {filter && (
        <div className="flex shadow-2xl bg-white w-max absolute z-index1 p-6">
        <div className="flex flex-col gap-4">
          <div>
            <p>Time Range</p>
          </div>
  
          <div className="flex gap-2">
            <button className="filter_button">Overall</button>
            <button className="filter_button">Last Week</button>
            <button className="filter_button">Last Month</button>
          </div>
          <div>
            {" "}
            <div>
              <FormControlLabel
                label="Threat Type"
                control={
                  <Checkbox
                    checked={checkedSingle}
                    // indeterminate={checkedSingle}
                    onChange={handleChangeThreat}
                  />
                }
              />
              {setOpenTwo &&
               <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                <FormControlLabel control={<Checkbox  onChange={(e)=>{handleChangeCheck()}} />} label="Critical" /> 
                <FormControlLabel control={<Checkbox  onChange={(e)=>{handleChangeCheck()}} />} label="High" /> 
                <FormControlLabel control={<Checkbox  onChange={(e)=>{handleChangeCheck()}} />} label="Medium" /> 
                <FormControlLabel control={<Checkbox  onChange={(e)=>{handleChangeCheck()}} />} label="Low" />  
              </Box>
              }
              {/* {children} */}
            </div>
          </div>
        </div>
      </div>
      //  <Filter/>
      )}

{/* <Modal
  open={openAssign}
  onClose={handleCloseAssign}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
  
>
<Box  >
<div className="assign-modal">
    <div className="modal-wrapper">
       asdasdasd
    </div>
</div>
  </Box>
</Modal> */}
 
<Modal
  open={open2}
  onClose={handleCloseOpen2}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
<Box  >
<div className="details-modal">
    <div className="modal-wrapper">
      {/* <div className="modal-img">
        <img src={dataModal?.screenshot_path} onError={({ currentTarget }) => {
    currentTarget.onerror = null;  
    currentTarget.src="/na.jpg";
    currentTarget.style.objectFit="contain";
  }} />
      </div> */}
      <div className="modal-text-content">
        <div className="content-title">App Details</div>
        <div style={{display:'flex'}}>
        <div className="product-details">
          <div className="details-item">
            <strong>Name:</strong>
            <span>{dataModal2?.app_name}</span>
          </div>
          <div className="details-item">
            <strong>App Title:</strong>
            <span>{dataModal2?.title}</span>
          </div>
          
          <div className="details-item">
            <strong>Url:</strong>
            <span><a target='blank__' style={{'color':'#009eff'}} href={dataModal2?.url}>{dataModal2?.url}</a></span>
          </div>  
        </div>
        <div className="product-details">
          <div className="details-item bl-2" style={{marginLeft:'100px',borderLeft:'1px solid grey'}}>  
              <FormControlLabel
                label="Safe"
                control={<Checkbox checked={dataModal2.unsafe == true ? 'checked':''} />}
              /><br></br>  
            </div>
        </div>
        </div>
      </div>
    </div>
</div>
  </Box>
</Modal>
 
<Modal
  open={open3}
  onClose={handleCloseOpen3}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
<Box  >
<div className="details-modal">
    <div className="modal-wrapper">
      {/* <div className="modal-img">
        <img src={dataModal?.screenshot_path} onError={({ currentTarget }) => {
    currentTarget.onerror = null;  
    currentTarget.src="/na.jpg";
    currentTarget.style.objectFit="contain";
  }} />
      </div> */}
      <div className="modal-text-content">
        <div className="content-title">Social Media Details</div>
        <div style={{display:'flex'}}>
        <div className="product-details">
          <div className="details-item">
            <strong>Title:</strong>
            <span>{dataModal3?.post_title}</span>
          </div>
          <div className="details-item">
            <strong>Url:</strong>
            <span><a style={{'color':'#009eff'}} target='blank__' href={dataModal3?.post_url}>{dataModal3?.post_url}</a></span>
          </div>   
          <div className="details-item">
            <strong>Likes:</strong>
            <span>{dataModal3?.no_of_likes}</span>
          </div>      
        </div>
        <div className="product-details">
          <div className="details-item bl-2" style={{marginLeft:'100px',borderLeft:'1px solid grey'}}>  
              <FormControlLabel
                label="Fake"
                control={<Checkbox checked={dataModal3?.is_fake_fraud == true ? 'checked':''} />}
              /><br></br>  
              <FormControlLabel
                label="Profanity"
                control={<Checkbox checked={dataModal3?.profanity == true ? 'checked':''} />}
              /><br></br>   
              <FormControlLabel
                label="Nudity"
                control={<Checkbox checked={dataModal3?.nudity == true ? 'checked':''} />}
              /><br></br>  
            </div>
        </div>
        </div>
      </div>
    </div>
</div>
  </Box>
</Modal>
 
 <Modal  open={openAssign}
  onClose={handleCloseAssign}> 
      <div className="details-moda1l">
    <div className="modal-wrapper">
      <div className="modal-img">
      <div className="modal-text-content">
        <div className="content-title">Select Assignee</div>
        <div className="product-details1">
          <div className="details-item1"> 
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select" 
              // value={Value}
              label="Select Assignee" 
              // onChange={handleChangeDropdown}
            >
              <MenuItem   >Test user 1</MenuItem> 
            </Select>
            </div>
            </div>
        </div>
        </div>
        </div>
        </div>
  </Modal>

<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
<Box  >
<div className="details-modal">
    <div className="modal-wrapper">
      <div className="modal-img">
        <img src={dataModal?.screenshot_path} onError={({ currentTarget }) => {
    currentTarget.onerror = null;  
    currentTarget.src="/na.jpg";
    currentTarget.style.objectFit="contain";
  }} />
      </div>
      <div className="modal-text-content">
        <div className="content-title">Title</div>
        <div style={{display:'flex'}}>
        <div className="product-details">
          <div className="details-item">
            <strong>Asset:</strong>
            <span>{dataModal?.domain}</span>
          </div>
          <div className="details-item">
            <strong>IP:</strong>
            <span>{dataModal?.ip_address}</span>
          </div>
          
          <div className="details-item">
            <strong>Threat Type:</strong>
            <span>{dataModal?.threat_type}</span>
          </div>
          <div className="details-item">
            <strong>Risk Score:</strong>
            <span>{dataModal?.risk_score}</span>
          </div>
          <div className="details-item">
            <strong>Rank:</strong>
            <span>{dataModal?.domain_rank}</span>
          </div>
          <div className="details-item">
            <strong>Safe:</strong>
            <span>{dataModal.unsafe == true ? 'Safe':'Unsafe'}</span>
          </div> 
         
          
        </div>
        <div className="product-details">
          <div className="details-item bl-2" style={{marginLeft:'100px',borderLeft:'1px solid grey'}}>  
              <FormControlLabel
                label="Safe"
                control={<Checkbox checked={dataModal.unsafe == true ? 'checked':''} />}
              /><br></br>
              <FormControlLabel
                label="Parking"
                control={<Checkbox checked={dataModal.parking == true ? 'checked':''} />}
              /> <br></br>
              <FormControlLabel
                label="Spamming"
                control={<Checkbox checked={dataModal.spamming == true ? 'checked':''} />}
              /><br></br>
              <FormControlLabel
                label="Malware"
                control={<Checkbox checked={dataModal.malware == true ? 'checked':''} />}
              /><br></br>
              <FormControlLabel
                label="Suspicious"
                control={<Checkbox checked={dataModal.suspicious == true ? 'checked':''} />}
              />
                 
            </div>
        </div>
        </div>
      </div>
    </div>
</div>
  </Box>
</Modal>


      <div className=" mt-10 mb-5 bg-white" style={{Border:"5px ",overflowX: "auto"}}> 
     {/*  {datanGet.length > 0 && <MUITable columns={columns} options={options} table={datanGet} />} */}
     {datanGet.length > 0  &&
     <table className='table table-custom'>
          <thead>
            <tr>
              <th>Threat Type</th>
              <th>Asset</th>
              <th>Risk Score</th> 
              <th>Screenshot</th> 
              <th>Details</th> 
              <th>Action</th> 
            </tr>
          </thead>
          <tbody>
         {datanGet.map((appData, index) => (  
           <tr className='border-bottom' key={index}>
              <td>{appData?.threat_type ?? '-'}</td> 
              <td>{appData?.domain ?? '-'}</td> 
              <td>{appData?.risk_score ?? '0'}</td> 
              <td > 
               <img className="defent-list-img" src={appData?.screenshot_path ?? '/na.jpg'} onError={({ currentTarget }) => {
    currentTarget.onerror = null;  
    currentTarget.src="/na.jpg";
  }}    /> 
                </td>
              <td><button className='bg-primary text-teal-50 shadow-lg shadow-indigo-500/50 px-3 py-1 rounded' onClick={()=>{handleOpen(appData)}}>Details</button></td>
              <td><button className='bg-primary text-teal-50 shadow-lg shadow-indigo-500/50 px-3 py-1 rounded' onClick={()=>{handleAssign(appData)}}>Assign</button></td>
            </tr> 
          ))}
          </tbody>
      </table>
    }
    {/* <Pagination
      PagePerRow={10}
    /> */}
      
     {/*  {datanApp.length > 0 &&<MUITable columns={columns2} options={options} table={datanApp} />} */}
        {datanApp.length > 0  &&
        <table className='table table-custom'>
          <thead>
            <tr>
              <th>Title</th>
              <th>Asset</th>
              <th>Device</th> 
              <th>Link</th> 
              <th>Detection Date</th> 
              <th>Detail</th> 
              <th>Action</th> 
            </tr>
          </thead>
          <tbody>
         {datanApp.map((appData, index) => (  
           <tr className='border-bottom' key={index}>
              <td><small>{appData?.title ?? '-'}</small></td> 
              <td>{appData?.app_name ?? '-'}</td> 
              <td>
                {appData?.device && appData?.device == 1 ? 'Android':''}
                {appData?.device && appData?.device == 2 ? 'Ios':''}
                {appData?.device && appData?.device == 3 ? 'Desktop':''}
                </td> 
              <td><a style={{'color':'#009eff'}}target='_blank' href={appData?.url} >Link <i className="fa fa-send"></i></a></td>
              <td><small>{appData?.created_at}</small></td>
              <td><button className='bg-primary text-teal-50 shadow-lg shadow-indigo-500/50 px-3 py-1 rounded' onClick={()=>{handleOpenApps(appData)}}>Details</button></td> 
              <td><button className='bg-primary text-teal-50 shadow-lg shadow-indigo-500/50 px-3 py-1 rounded' onClick={()=>{handleAssign(appData)}}>Assign</button></td>
            </tr> 
          ))}
          </tbody>
        </table>
        }

        {datanProduct.length > 0  &&
        <table className='table table-custom'>
          <thead>
            <tr>
              <th>Title</th> 
              <th>Price</th> 
              <th>Rating</th> 
              <th>Details</th> 
              <th>Action</th> 
            </tr>
          </thead>
          <tbody>
         {datanProduct.map((appData, index) => (  
           <tr className='border-bottom' key={index}>
              <td>{appData?.product_name ?? '-'}</td> 
              <td>{appData?.currency } {appData?.product_mrp }</td> 
              <td>{appData?.seller_ratings ?? '*'}</td> 
              <td><a style={{'color':'#009eff'}} title={appData?.product_url ?? '-'} target='_blank' href={appData?.product_url ?? '-'} >Link <i className="fa fa-send"></i></a></td> 
              <td><button className='bg-primary text-teal-50 shadow-lg shadow-indigo-500/50 px-3 py-1 rounded' onClick={()=>{handleAssign(appData)}}>Assign</button></td>
            </tr> 
          ))}
          </tbody>
        </table>
        }

        {datanSocial.length > 0  &&
        <table className='table table-custom'>
          <thead>
            <tr> 
              <th>Title</th>
              <th>Social Network</th> 
              <th>Profanity</th> 
              <th>Details</th>
              <th>Action</th> 
            </tr>
          </thead>
          <tbody>
         {datanSocial.map((appData, index) => (  
           <tr className='border-bottom' key={index}>
              <td>{appData?.post_title ?? '-'}</td> 
              <td><a style={{'color':'#009eff'}} title={appData?.post_url ?? '-'} target='_blank' href={appData?.post_url ?? '-'} >Link <i className="fa fa-send"></i></a></td> 
              <td>{appData?.profanity && appData?.profanity == true ? 'True':'false'}</td> 
              <td><button className='bg-primary text-teal-50 shadow-lg shadow-indigo-500/50 px-3 py-1 rounded' onClick={()=>{handleOpenSocial(appData)}}>Details</button></td> 
              <td><button className='bg-primary text-teal-50 shadow-lg shadow-indigo-500/50 px-3 py-1 rounded' onClick={()=>{handleAssign(appData)}}>Assign</button></td>
            </tr> 
          ))}
          </tbody>
        </table>
        }


         {loader == true && <div  className="flex items-end justify-around pt-5 gap-5 pb-5"><CircularProgress /></div>}
      </div>
      {previousLink && <Button variant="contained" style={{ textTransform: 'none', marginTop: '10px' }} onClick={() => previous()}><i className='fa fa-angle-double-left'></i></Button>} &nbsp;
      {nextLink && <Button variant="contained" style={{ textTransform: 'none', marginTop: '10px' }} onClick={() => next()}><i className='fa fa-angle-double-right'></i></Button>}
      <div style={{float:'right',color: 'black',padding: '7px',borderRadius: '4px',background: 'beige'}}>
        <div >Showing  {showing < 10 ? 'last '+showing: ''} {showing == 10 ? 'First '+10: ''}{showing > 10 && showing <= allTotal ? showing-10+' - '+showing : ''} of {allTotal ?? 0}</div>
      </div>
    </div>
  );
};

export default Index;
