import API_END_POINTS from '../../../Config/integrations';
import { getApiResponseObject } from '../restApisFunctions';

const getproductsdata = () => { 
  const productsdata = async () => {  
    const response = await getApiResponseObject(
      API_END_POINTS.productResultGet, 
      JSON.stringify(),
    );  
    return response;
  }; 
  return {
    productsdata
  };
};
export default getproductsdata;