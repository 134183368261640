import React,{useState} from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom'
import Add_User from "./Add_User"
import Manage_User from "./Manage_User"


const Index = () => {
      const [addUser, setAddUser] = useState(true)
      const [manage, setManage] = useState("")
 const previous = () =>{
      window.history.back()   
 }; 

  return (
    <div className='flex flex-col gap-10  p-5 h-278 overflow-y-auto bg-gray-50'>
      <div className='flex gap-4  overflow-y-auto'>
             <KeyboardBackspaceIcon className="cursor-pointer" onClick={() =>previous()}/>
            <p>User Management</p>
      </div>
      <div className="flex  gap-1 border-b h-10 text-neutral-500">
   
   <p className= {`summary  ${addUser && 'text'}`}  onClick={()=>(
     setAddUser(true),
     setManage(false)
     )}>Add User</p>
     
   <p className={`summary  ${manage && 'text'}`}  onClick={()=>(
     setManage(true),
     setAddUser(false)
   )} >Manage</p>
 </div> 
 {addUser && 
   <Add_User/>
 }
 {manage && 
   <Manage_User/>
 }

    </div>
  )
}

export default Index;