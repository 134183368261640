import { API_BASE_URL } from './env';

const API_END_POINTS = {
  login: `${API_BASE_URL}/login/`, // Get the token if params are correct
  logout :`${API_BASE_URL}/logout/`,// If access token is invalid
  register:`${API_BASE_URL}/register/`,// for user register
  token : `${API_BASE_URL}/token/`,
  userTokenRefresh: `${API_BASE_URL}/token/refresh/`, // Refreshes the user access and refresh token
  accessTokenVerify: `${API_BASE_URL}/token/verify/`, // Verify access token
  healthCheck : `${API_BASE_URL}/health-check/`,// For healt check status
  testAsync : `${API_BASE_URL}/test-async/`,
  //Domain
  domainsConfigGet:`${API_BASE_URL}/domains/config/`,
  domainsConfigPost:`${API_BASE_URL}/domains/config/`,
  domainsScanGet: `${API_BASE_URL}/domains/scan/`,
  domainsScanPost:`${API_BASE_URL}/domains/scan/`,
  nextdomain:`${API_BASE_URL}/domains/results/`,
  domainsResultsGet:`${API_BASE_URL}/domains/result/`,
  //App
  appConfigGet:`${API_BASE_URL}/app/config/`,
  appConfigPost:`${API_BASE_URL}/app/config/`,
  nextApp:`${API_BASE_URL}/app/results/`,
  prevApp:`${API_BASE_URL}/app/results/`,
  appResultsGet:`${API_BASE_URL}/app/result/`,
  appScanGet: `${API_BASE_URL}/app/scan/`,
  appScanPost:`${API_BASE_URL}/app/scan/`,
  //Product
  productConfigGet:`${API_BASE_URL}/product/config/`,
  productConfigPost:`${API_BASE_URL}/product/config/`,
  productResultGet:`${API_BASE_URL}/product/result/`,
  productScanGet:`${API_BASE_URL}/product/scan/`,
  productScanPost:`${API_BASE_URL}/product/scan/`,
  //Social Media
  socialConfigGet:`${API_BASE_URL}/social/config/`,
  socialConfigPost:`${API_BASE_URL}/social/config/`,
  socialResultGet:`${API_BASE_URL}/social/result/`,
  socialScanGet:`${API_BASE_URL}/social/scan/`,
  socialScanPost:`${API_BASE_URL}/social/scan/`,

  //User
  userGet: `${API_BASE_URL}/user/`,
  //Marketplace
  marketplaceGet:`${API_BASE_URL}/marketplace`,
};

export default API_END_POINTS;
