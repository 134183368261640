import API_END_POINTS from '../../../../../Config/integrations';
import { getApiResponseObject } from '../../../../Common/restApisFunctions';

const prevForm = () => {
  const prevdata = async (previousLink) => { 
    const response = await getApiResponseObject(
        previousLink,
    );
    // var response = 'asd'
    return response;
  };
  return {
    prevdata
  };
};

export default prevForm;