import API_END_POINTS from '../../../../../Config/integrations';
import { postWithResponseObject } from '../../../../Common/restApisFunctions';

const postDomainForm = () => {
  const signupdomain = async (apex_domain_name,top_level_domain) => {
    let item =  {
      "apex_domain_name": apex_domain_name, 
    } ;
    const response = await postWithResponseObject(
      API_END_POINTS.domainsConfigPost,
      JSON.stringify(item),
    );
    return response;
  };
  return {
      signupdomain
  };
};

export default postDomainForm;