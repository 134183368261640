import React from 'react'

const PageNotfound = () => {
  return (
    <div className='flex items-center justify-center h-screen'>
      <h2 className='text-7xl text-red-500'>Internal Server Error....</h2>
      </div>
  )
}

export default PageNotfound