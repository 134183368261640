import API_END_POINTS from '../../../../../Config/integrations';
import { getApiResponseObject } from '../../../../Common/restApisFunctions';

const getAllDomains = () => { 
  const domains = async () => {  
    const response = await getApiResponseObject(
      API_END_POINTS.domainsConfigGet, 
      JSON.stringify(),
    );  
    return response;
  }; 
  return {
      domains
  };
};

export default getAllDomains;