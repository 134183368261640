import API_END_POINTS from '../../../Config/integrations';
import { postWithResponseObject } from '../../Common/restApisFunctions';

const UseLoginForm = () => {
  const loginUser = async (username, password) => {
    let data = { user: { username, password } };
    const response = await postWithResponseObject(
      API_END_POINTS.login,
      JSON.stringify(data),
      //localStorage.setItem('token-info', JSON.stringify(data))
    );
    return response;
  };
  return {
    loginUser
  };
};

export default UseLoginForm;
