import React, { useState } from "react";
import { Box, Button, InputLabel, TextField } from "@mui/material";
import postsignupSocial from "./postsignupSocial";
import swal from "sweetalert";
const Index = () => {
  const { signupSocial } = postsignupSocial();
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [input2, setInput2] = useState("");
  const [tags2, setTags2] = useState([]);
  const [isKeyReleased2, setIsKeyReleased2] = useState(false);

  const onChange = (e) => {
    const { value } = e.target; 
    setInput(value);
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (
      (key === "," && trimmedInput.length && !tags.includes(trimmedInput)) ||
      (key === "Enter" && trimmedInput.length && !tags.includes(trimmedInput))
    ) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };




  const onChange2 = (e) => {
    const { value } = e.target; 
    setInput2(value);
  };

  const onKeyDown2 = (e) => {
    const { key } = e;
    const trimmedInput = input2.trim();

    if (
      (key === "," && trimmedInput.length && !tags2.includes(trimmedInput)) ||
      (key === "Enter" && trimmedInput.length && !tags2.includes(trimmedInput))
    ) {
      e.preventDefault();
      setTags2((prevState) => [...prevState, trimmedInput]);
      setInput2("");
    }

    if (key === "Backspace" && !input2.length && tags2.length && isKeyReleased2) {
      const tagsCopy = [...tags2];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags2(tagsCopy);
      setInput2(poppedTag);
    }

    setIsKeyReleased2(false);
  };

  const onKeyUp2 = () => {
    setIsKeyReleased2(true);
  };

  const [productData, setProductData] = useState({
    handles: "",
    social_network: "",
    exclude_words: "",
    excluded_username: "",
  });
  const [inputValidation, setInputValidation] = useState({
    handles: "",
  });
  const handleChangeInput = (e) => {
    e.preventDefault();
    setProductData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setInputValidation((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault(); 
    var errorHandle = false;
    if (tags.length == 0) {
      swal({
        title: "Handle is Required",
        text: "Atleast add one handle",
        icon: "warning",
        button: "ok",
      });
      /*  document.getElementById("social_network")?.focus(); */
      /* setInputValidation((prevState) => ({
                    ...prevState,
                    social_network: "Handle is required.",
                  })); */
      errorHandle = true;
    }
    if (!errorHandle) {
      var items = {
        social_network: tags.join(','),
        exclude_words: productData.exclude_words,
        excluded_username: tags2.join(','),
        keyword_hashtag: "social_icon",
      };
      const response = await signupSocial(items);
      //console.log(response);
      if (response.status == 201) {
        setProductData((prevState) => ({
          ...prevState,
          social_network: "",
          exclude_words: "",
          excluded_username: "",
        }));
        swal({
          title: "Social network",
          text: "Added Successfully",
          icon: "success",
          button: "ok",
        });
      } else {
        swal({
          title: "Social network failed to add",
          text: response?.data?.social_network[0],
          icon: "error",
          button: "ok",
        });
      }
    }
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };
  const deleteTag2 = (index) => {
    setTags2((prevState) => prevState.filter((tag, i) =>  i !== index));
  };
  return (
    <div className="flex justify-between">
      <div className="flex flex-col  gap-3   p-4  mt-2  bg-white rounded-3xl shadow-md overflow-hidden w-2/4">
        <div className="flex flex-col gap-1">
          <div className="container_s">
            {tags.map((tag, index) => (
              <div key={index} className="tag">
                {tag}{" "}
                <button className="deleteTag" onClick={() => deleteTag(index)}>
                  x
                </button>
              </div>
            ))}
            <input
              label="Your Handles"
              variant="outlined"
              placeholder="Enter handle (Press enter or comma to add tag)"
              value={input}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onChange={onChange}
            />
          </div>

          {/*  <TextField
            label="Your Handles"
            variant="outlined"
            id='social_network'
            fullWidth
            name="social_network"
            value={productData?.social_network}
            placeholder="New handles..."
            onChange={handleChangeInput}
          /> */}
          <p className="twitter_inputs">
            Mutiple handles Seperated by comma (NOTE : Press enter or comma to
            add tag)
          </p>
        </div>
        <div className="flex flex-col gap-1">
          <TextField
            label="Keywords to look for"
            variant="outlined"
            fullWidth
            id="exclude_words"
            placeholder="New keyword..."
            name="exclude_words"
            onChange={handleChangeInput}
            value={productData?.exclude_words}
          />
          <p className="twitter_inputs">Multiple keywords Seperated by comma</p>
        </div>
        <div className="flex flex-col gap-1">
          {/* <TextField
            label="Handle to exclude from scan"
            variant="outlined"
            fullWidth
            id='excluded_username'
            placeholder="New handle..."
            name="excluded_username"
              onChange={handleChangeInput}
            value={productData?.excluded_username}
          /> */}
          <div className="container_s">
            {tags2.map((tag, index) => (
              <div key={index} className="tag">
                {tag}{" "}
                <button className="deleteTag" onClick={() => deleteTag2(index)}>
                  x
                </button>
              </div>
            ))}
            <input
              label="Your Handles"
              variant="outlined"
              placeholder="Enter handle to exclude from scan (Press enter or comma to add tag)"
              value={input2}
              onKeyDown={onKeyDown2}
              onKeyUp={onKeyUp2}
              onChange={onChange2}
            />
          </div>
          <p className="twitter_inputs">
            Multiple handles Seperated by comma (NOTE : Press enter or comma to
            add tag)
          </p>
        </div>
        <InputLabel>
          <p className="text-orange-600 text-sm">{inputValidation?.handles} </p>
        </InputLabel>
        <div className="flex  ">
          <Button
            variant="contained"
            style={{ padding: "0px 50px" }}
            onClick={handleSubmit}
            className="capitalize"
          >
            Save
          </Button>
          {/*  <Button variant="contained" style={{backgroundColor: "#fff", color:'#000000de'}} className="capitalize">
            Clear
          </Button> */}
        </div>
      </div>

      <div className="flex flex-col  gap-3 mx-4 p-4  mt-2  bg-white rounded-3xl shadow-md overflow-hidden w-2/4 ">
        <h1>Help?</h1>
        <p className="text-neutral-400">Fake Social Media scanner</p>
        <div className="flex flex-col gap-3">
          <p className="domainscan_guide">
            Step 1: Add the twitter handles you would like to scan in the “Your
            handle” Text box.
          </p>
          <p className="domainscan_guide">
            Step 2: Add Keywords you would like to scan in the “Keywords to look
            for “ Text box.
          </p>
          <p className="domainscan_guide">
            Step 3: Add Handles whose tweets you would like to exclude from the
            result of the scan.
          </p>
          <p className="domainscan_guide">
            Step 4: Now you can setup the Scan cycle for Twitter Scanner
          </p>
          <p className="domainscan_guide">Step 6: Setup the Scan cycle.</p>
        </div>
      </div>
    </div>
  );
};

export default Index;
