import API_END_POINTS from '../../../../../Config/integrations';
import { postWithResponseObject } from '../../../../Common/restApisFunctions';

const postsignupSocial = () => {
  const signupSocial = async (item) => { 
    const response = await postWithResponseObject(
      API_END_POINTS.socialConfigPost,
      JSON.stringify(item),
    );
    return response;
  };
  return {
    signupSocial
  };
};

export default postsignupSocial;