import API_END_POINTS from '../../../../../Config/integrations';
import { postWithResponseObject } from '../../../../Common/restApisFunctions';

const getsocialcan = () => { 
         
      const scanSoc = async (id) => {
        let item =  {
          social_config:id, 
        };
        const response = await postWithResponseObject(
          API_END_POINTS.socialScanPost,
          JSON.stringify(item),
        );
        return response;
      };
      return {
        scanSoc
      };
};  
export default getsocialcan;