import  React, {useState} from "react";
import Summary from '../../Common/Summary'
import Defend from '../../Common/Defend'


const Index = () => {
  const [summary, setSummary] = useState(true)
  const [defend, setDefend] = useState(false)  
  return (
    <div className="flex  flex-col pr-5 text-black mb-4">
      <div className="flex  gap-1 border-b h-10 ">
   
        <p className= {`summary  ${summary && 'text'}`}  onClick={()=>(
          setSummary(true),
          setDefend(false)
          
          )}>Summary</p>
          
        <p className={`summary  ${defend && 'text'}`}  onClick={()=>(
          setDefend(true),
          setSummary(false)
        )} >Defend</p>
      </div> 
      {summary &&
        <Summary/>
      }
      {defend && 
        <Defend/>
      }
    </div>
  );
};
export default Index;
