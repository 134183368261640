import React, { useState,useEffect} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import getAllProducts from '../getAllProducts'


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Index = ({closePopUp,userData}) => {
  console.log("userData",userData)
  const [open, setOpen] = useState(true);   
  
  return (
    <div>
      <Modal
        open={open}
        //onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: 1200 }} className="flex flex-col">
           <div>
            <h4 className="text-primary">Asset: <span className="text-sm">Shirts</span></h4>
           </div>
           <div className="flex gap-2 p-5">
            <div className=" domainscan_guide flex items-center justify-center bg-white  shadow-md overflow-hidden  w-2/4 h-60">
              image not available
            </div>
            <div className="flex flex-col gap-4">
             <h4>Detection Date: <span className="domainscan_guide"></span> </h4>
             {/* { userData.length && userData.map((item, index)=>(
              <spam key={index}> */}
                   <h4>Price Range: <span className="domainscan_guide">{userData[0]?.price_range}</span></h4>
              {/* </spam>
             )) } */}
             
             <Button onClick={() => closePopUp(false)} color="error" style={{textTransform: 'none'}}  className="W-10">Delete Product</Button>
            </div>
           </div>
           <Button onClick={() => closePopUp(false)} className="self-end">Close</Button> 
        </Box>
      </Modal>
    </div>
  );
};
export default Index;
