import React from 'react'
import onboarding from "../../../Assets/images/waiting.png"
import { TabTitle } from '../../../Config/generalFunctions'
import './style.scss'
import swal from 'sweetalert';
const Index = () => {
  TabTitle(' Onboarding | Truviss ')
  return (
    <div className='onboarding_main'>
        <div className='onboarding_left'>
         <p className='onboarding_wishes'>Hey There!</p>
         <h3 className='onboarding_mail_verification'>Please Verify Your Email <br/>address to continue.</h3>
         <p className='verify_link'>Click to <span>resend</span> Verification email</p>
         <p className='onboarding_contact_mail'>or contact us on <span>infinite@acviss.com</span></p>
        </div>
        <div className='onboarding_right'>
         <img src={onboarding} alt="/"  className='waiting_image'/> 
        </div>
    </div>
  )
}
export default Index;
