import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TabTitle } from '../../../Config/generalFunctions'
import { getAllProductData } from '../../../Redux/reducers/dashboardSlice'
import Sub_tab from '../../Components/Sub_tab'
const Index = () => {
  TabTitle('Products | Truviss')
  const allProductData = useSelector((state) => state.dahsboardData.allProductData)
  const dispatch = useDispatch()
  console.log(allProductData)
  useEffect(() => {
    if (allProductData === null) {
      dispatch(getAllProductData())
    }
  }, [allProductData])
  return (
    <div className='p-5 h-278 bg-gray-50 overflow-y-scroll'>
      <Sub_tab/>
    </div>
  )
}
export default Index;
