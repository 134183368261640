import React from "react";
import { TextField, Autocomplete } from "@mui/material";
import { TabTitle } from "../../../Config/generalFunctions";
const Index = () => {
  TabTitle('Alarms | Truviss')
  const options = ["Option 1", "Option 2"];
  return (
    <div className="h-278 bg-gray-50 p-5 ">
      <div className="flex justify-between items-center mt-10 flex-wrap" >
        <p className="opacity-90  tracking-wider  font-sans text-xl">Alarms</p>
        <div className="flex gap-3 flex-wrap">
          <div className="text-black">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={options}
            sx={{ width: 200 }}
            renderInput={(params) => <TextField {...params} label="Module" />}
          />
          </div>
        <div>
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={options}
            sx={{ width: 200 }}
            renderInput={(params) => (
              <TextField {...params} label="Date Range" />
            )}
          />
        </div>
         
        </div>
      </div>
      <div className="flex justify-center pt-20">
        <p className="text-black">No data available</p>
      </div>
    </div>
  );
};
export default Index;
