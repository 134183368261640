import React,{useState} from 'react'
import MUITable from "../../../../Common/MUITable";
import {data} from "./Json_data";
import Pagination from '../../../../Common/Pagination'
import Button from '@mui/material/Button';
import User_Edit from './User_Edit'



const Index = () => {
  const [userEdit, setUserEdit] = useState(false)
const options = {
    filter: false,
    filterType: "dropdown",
    responsive: "vertical",
    selectableRows: "none",
    download: false,
    print: false,
    sort: false,
    viewColumns: false,
    search: false,
    textLabels: {
      body: {
        noMatch: (
          <div className="no_data_found">
            <p>No data Found...</p>
          </div>
        ),
      },
    },
  };
const columns = [
    { name: "name", label: "Name" },
    { name: "email", label: "Email" },
    { name: "phone", label: "Phone" },
    { name: "role", label: "Role" },
    { name: "edit", 
      label: "Edit" ,
      options: {
        customBodyRender: (value) => {
          return (
            <div>
                <Button  variant="contained" onClick={() => setUserEdit(true)} style={{textTransform: 'none'}} sx={{ mt: 1, mr: 1 }}>
                      Edit.
                 </Button>
            </div>
          );
        },
      },
    },
  ];
  return (
     <div className="flex flex-col justify-center mt-10">
    <MUITable columns={columns} options={options} table={data} />
    <Pagination
      PagePerRow={10}
    />
    { userEdit && (
      <User_Edit setUserEdit={setUserEdit}/>
    )}
</div>
  )
}

export default Index