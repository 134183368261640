import React from 'react'
import { TabTitle } from '../../../Config/generalFunctions';

const Index = () => {
  TabTitle('Notifications | Truviss ')
  return (
    <div className='h-278 bg-gray-50 p-5 text-black'>
      <p className='font-sans text-2xl '>Notifications</p>
      <div className='h-50 flex justify-center items-center'>
        <p className='text-middle'>No data available</p>
      </div>
    </div>
  )
}
export default Index;
