import React, { useState, useEffect } from "react";
import {
  Typography,
  Pagination,
  Autocomplete,
  TextField,
  PaginationItem,
} from "@mui/material";
import "./styles.scss";


const firstIndex = 0;
const PaginationControlled = () => {
  const [page, setPage] = useState({
    page: 1,
    jumptopage: "1",
    option: [2],
  });
  return (
    <div className="pagination_top_container">
      <div className="pagination_sub_container">
        <Typography>Page :</Typography>
        <Pagination
          page={page?.page}
          renderItem={(item) => <PaginationItem {...item} />}
          hideNextButton={true}
          hidePrevButton={true}
        />
      </div>
      <div className="jump_to_page">
        <p>Jump to page :</p>
        <Autocomplete
          value={page?.jumptopage}
          name="jump_to_page"
          onChange={(event, newValue) => {
          }}
          id="controllable-states-demo"
          options={page?.option}
          getOptionLabel={(option) => option?.toString()}
          renderInput={(params) => (
            <TextField
              type="number"
              placeholder="1"
              className="inputfield-box"
              {...params}
              InputLabelProps={{
                shrink: false,
              }}
            />
          )}
        />
      </div>
    </div>
  );
};
export default PaginationControlled;
