import React, { useState,useEffect } from "react";
import { Box, Button } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { TextField, Autocomplete,CircularProgress,FormControlLabel,Checkbox } from "@mui/material";
import { TabTitle } from "../../../../../Config/generalFunctions";
import {RESPONSE_CODES} from '../../../../../Config/definations'
import swal from 'sweetalert';
import getAllApps from '../Apps/getAllApps';
import appScan from './service'
import nextForm from '../Apps/nextForm';
import prevForm from '../Apps/prevForm';
import { useSelector } from "react-redux";
const Index = () => {
  TabTitle('Fake Domain Scanner Service')
  const dropdown = ["Day", "Week"];
  const dropdown1 = ["Immediately", "Next Schedule"];
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  const [toggle, setToggle] = useState(false);
  const [showbar, setshowbar] = useState(true)
  const [allApps, setAllApps] = useState([])
  const [nextLink, setNextApp] = useState('');
  const [previousLink, setPreviousApp] = useState('');
  const [loader, setLoader] = useState(false);
  const {startScanApps} = appScan();
  const {nextData} = nextForm();
  const {prevdata} = prevForm();
  const [scanitems, setCheckbox] = useState([]);
  const [scanData, setScanData] = useState({
    days : "",
    week : "",
    scan : ""
  })

const handleChange = (e) =>{
  setScanData(() => ({
    [e.target.name]: e.target.value,
  }));
}


const handleChangeCheck = (item) => {

  setCheckbox(scanitems.includes(item) ? scanitems.filter(e => e !== item) : [...scanitems, item]);

};

  const allAppConfiq = useSelector((state) => state.confiqData.allAppConfiq)

const getData = async () => {
  setLoader(true);

  if (allAppConfiq) {
    setLoader(false);
  }
  if (allAppConfiq.results.length > 0) {

    setAllApps([...allAppConfiq.results]);
    if (allAppConfiq.next_page) {
      setNextApp(allAppConfiq.next_page); 
    }else{
      setNextApp(); 
    } 
    if (allAppConfiq.prev_page) {
      setPreviousApp(allAppConfiq.prev_page); 
    }else{
      setPreviousApp(); 
    }  
  }
  setshowbar(false);
} 
useEffect(()=>{
  getData();
}, [allAppConfiq]) 


const scanApps= async () =>{ 
  if(scanitems.length > 0){
    for(var i=0;i<scanitems.length;i++){
      var id = scanitems[i];
      const response = await startScanApps(id);
      if(response){ 
      }
      if(i === scanitems.length -1){ 
        let status = response.status; 
        if (status == 201) {
          swal({
            title:"Scan Cycle",
            text:"Started Successfully",
            icon:"success",
            button:"ok",  
          }).then(()=>{
            setCheckbox([])
          });
        }else {
          swal({
              title:response.status,
              text:"Something went wrong, try again later ...",
              icon:"error",
              button:"ok",
              icon:"error", 
            })
        }
      }
    }
  }else{
    swal({
      title:"Please select at least one",
      text:"Before starting scan", 
      button:"ok",
      icon:"error",
    })
  } 
  
}

const next = async () =>{ 
  const response = await nextData(nextLink); 
  console.log(response)
  if (response.results.length > 0) {
    setAllApps([]); 
    setAllApps([...response.results]);
    if (response.next_page) {
      setNextApp(response.next_page); 
    }else{
      setNextApp(); 
    } 
    if (response.prev_page) {
      setPreviousApp(response.prev_page); 
    }else{
      setPreviousApp(); 
    }  
  }
}
const previous = async () =>{ 
  const response = await prevdata(previousLink);
  if (response.results.length > 0) {
    setAllApps([]); 
    setAllApps([...response.results]);
    if (response.next_page) {
      setNextApp(response.next_page); 
    }else{
      setNextApp(); 
    } 
    if (response.prev_page) {
      setPreviousApp(response.prev_page); 
    }else{
      setPreviousApp(); 
    }  
  }
}


  return (
    <div className="flex justify-between">
      <div className="flex flex-col gap-3   p-4  mt-2  bg-white rounded-3xl shadow-md overflow-hidden w-2/4">
        {/* <div className="flex items-center gap-2">
          <p className="text-neutral-600">scan enabled</p>
          <Switch {...label} onClick={() => setToggle(!toggle)}/>
        </div>
        {toggle && 
        <div className="flex items-center flex-col gap-5">
        <div className="flex items-center ">
          <p className="text-neutral-600">Repeat scan every</p>
          <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
            <OutlinedInput
              aria-describedby="outlined-weight-helper-text"
             value={1}
             name="Day"
              //value={scanData?.days}
              onChange={(e) => handleChange(e)}
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </FormControl>
          <Autocomplete
            fullWidth
            name='week'
            //value={week}
            onChange={(e) => handleChange(e)}
            options={dropdown}
            sx={{ width: 200 }}
            value={dropdown?.[0]}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </div>
            <Autocomplete
            fullWidth
            name="scan"
            value={scanData?.scan}
            onChange={(e) => handleChange(e)}
            options = {dropdown1}
            sx={{ width: 665 }}
            renderInput={(params) => (
              <TextField {...params} label="startscan"/>
            )}
          />
      </div>
        } */}
        {allApps.length > 0 ? (Object.entries(allApps).map((val, key) => {
           if(val[1].device == 1){
            var selected = 'Android';
          }else if(val[1].device == 2){
            var selected = 'IOS'; 
          }else if(val[1].device == 3){
            var selected = 'Desktop'; 
          } 
          return ( 
            <div key={key} className='flex  items-center   pt-5 gap-5'>
              <FormControlLabel key={val[1].id} control={<Checkbox key={'check' + val[1].id} value={val[1].id} name={val[1].app_name} checked={scanitems.includes(val[1].id)} onChange={(e) => { handleChangeCheck(val[1].id) }} />} label="" />
             <h3>{val[1].app_name ?? ''} - {selected ?? ''}</h3>
             </div>
            
          )})):'No data found !'}
          
          {loader == true && <div  className="flex items-end justify-around pt-5 gap-5"><CircularProgress /></div>}
        <div className='d-flex'>
         {previousLink && <Button variant="contained"  style={{textTransform: 'none'}} onClick={ () => previous()}>Previous</Button>} &nbsp;
         {nextLink && <Button variant="contained"  style={{textTransform: 'none'}} onClick={ () => next()}>Next</Button>}
        </div>
          <hr></hr>
          <div className='d-flex'>
          <Button variant="contained" className="capitalize " onClick={() => scanApps()}>
            Start Scan
          </Button>
        </div>
        
      </div>
      <div className="flex flex-col  gap-3 mx-4 p-4  mt-2  bg-white rounded-3xl shadow-md overflow-hidden w-2/4">
        <h1>Help?</h1>
        <p className="text-neutral-400">Fake domain scanner</p>
        <div className="flex flex-col gap-2">
          <p className="domainscan_guide">
            Click on “Add a domain” and enter the domain name in the text box.
          </p>
          <p className="domainscan_guide">
            Step 2: Click on “Verify” and enter a company email address you have
            access to that belongs to the same domain.
          </p>
          <p className="domainscan_guide">
            Step 3: Click on “Verify email” and a verification request will be
            sent to Truviss customer support.
          </p>
          <p className="domainscan_guide">
            Step 4: Once the email is verified you can proceed to setup the Scan
            cycle..
          </p>
          <p className="domainscan_guide">Step 6: Setup the Scan cycle.</p>
        </div>
      </div>
    </div>
  );
};

export default Index;
