import React, { useState,useEffect } from "react";
import { Box, Button } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { TextField, Autocomplete,FormControlLabel,Checkbox,CircularProgress } from "@mui/material";
import { TabTitle } from "../../../../../Config/generalFunctions";
import getDomainScan from './service';
import {RESPONSE_CODES} from '../../../../../Config/definations';
import swal from 'sweetalert';
import getAllDomains from '../Domains/getAllDomains';
import { v4 as uuidv4 } from 'uuid';
import nextForm from '../Domains/nextForm';
import { SportsEsportsOutlined } from "@material-ui/icons";
import { useSelector } from "react-redux";
const ScanSycle = () => {
  TabTitle('Fake Domain Scanner Service')
  const dropdown = ["Day", "Week"];
  const dropdown1 = ["Immediately", "Next Schedule"];
  const label = { inputProps: { "aria-label": "Size switch demo" } };
  const [toggle, setToggle] = useState(false);
  const [loader, setLoader] = useState(false);
  const {scanDomain} = getDomainScan();
  const [scanitems, setCheckbox] = useState([]);
  const [nextLink, setNextDomain] = useState('');
  const [previousLink, setPreviousDomain] = useState('');
  const {nextData} = nextForm();
  const [scanData, setScanData] = useState({
    days : "",
    week : "",
    scan : ""
  })
  const [alldomain, setAllDomain] = useState([])
  const {domains} = getAllDomains();

const handleChange = (e) =>{
  setScanData((prevState) => ({
    ...prevState,
    [e.target.name]: e.target.value,
  }));
}
 
 

// const handleChangeCheck = (item) => {
//   let allitems = scanitems; 
//   if (allitems.some((singleItem) => singleItem === item)) { 
//     allitems = allitems.filter((singleItem) =>  
//     singleItem !== item
//     );
//   } else {
//     allitems.push(item);
//   } 
//   setCheckbox(allitems); 
// };
const handleChangeCheck = (item) => {

  setCheckbox(scanitems.includes(item) ? scanitems.filter(e => e !== item) : [...scanitems, item]);

};
  const allDomainConfiq = useSelector((state) => state.confiqData.allDomainConfiq)
const getData = async () => {
  setLoader(true);

  if (allDomainConfiq) {
    setLoader(false);
  } 
  if (allDomainConfiq.results.length > 0) {
    setAllDomain([...allDomainConfiq.results]);
    if (allDomainConfiq.next_page) {
      setNextDomain(allDomainConfiq.next_page); 
    }else{
      setNextDomain(); 
    } 
    if (allDomainConfiq.prev_page) {
      setPreviousDomain(allDomainConfiq.prev_page); 
    }else{
      setPreviousDomain(); 
    } 
  }
}
useEffect(()=>{
  getData();
}, [allDomainConfiq]) 

const scanDomainget= async () =>{
    //const new_domain = scanData.replace(/https?:\/\/(?:www\.)?/, "").append(".");
    // console.log("%%%%%%%",data)
    // .then((res) => console.log(res))
    // .catch((err) => console.log(err))
    
    if(scanitems.length > 0){
      for(var i=0;i<scanitems.length;i++){
        var id = scanitems[i];
        const response = await scanDomain(id, uuidv4());
        if(response){ 
        }
        if(i === scanitems.length -1){ 
          let status = response.status; 
          if (status == 201) {
            swal({
              title:"Scan Cycle",
              text:"Started Successfully",
              icon:"success",
              button:"ok",  
            }).then(()=>{
              setCheckbox([])
            });
          }else {
            swal({
                title:response.status,
                text:"Something went wrong, try again later ...",
                icon:"error", 
                button:"ok",  
              })
          }
        }
      }
    }else{
      swal({
        title:"Please select at least one",
        text:"Before starting scan",
        icon:"error", 
        button:"ok",  
      })
    } 
    
}
const next = async () =>{
  const response = await nextData(nextLink); 
  if (response.status == 200 && response.results.length > 0) {
    setAllDomain([]);
    setAllDomain([...response.results]);
    if (response.next_page) {
      setNextDomain(response.next_page); 
    }else{
      setNextDomain(); 
    } 
    if (response.prev_page) {
      setPreviousDomain(response.prev_page); 
    }else{
      setPreviousDomain(); 
    } 
  }
}
const previous = async () =>{ 
  const response = await next(nextLink);
  if (response.status == 200 && response.results.length > 0) {
    setAllDomain([]);
    setAllDomain([...response.results]);
    if (response.next_page) {
      setNextDomain(response.next_page); 
    }else{
      setNextDomain(); 
    } 
    if (response.prev_page) {
      setPreviousDomain(response.prev_page); 
    }else{
      setPreviousDomain(); 
    } 
  }
}


  return (
    <div className="flex justify-between">
      <div className="flex flex-col  gap-3  p-4  mt-2  bg-white rounded-3xl shadow-md overflow-hidden w-2/4">
      {alldomain.length > 0 ? (Object.entries(alldomain).map((val, key) => {
        return (<div key={key} className='flex  items-center   pt-5 gap-5'>
          <FormControlLabel control={<Checkbox value={val[1].id} name={val[1].apex_domain_name} checked={scanitems.includes(val[1].id)} key={key} onChange={(e) => { handleChangeCheck(val[1].id) }} />} label="" />
             <h3>{val[1].apex_domain_name ?? ''}.{val[1].top_level_domain != 'undefined' ? val[1].top_level_domain : ''}</h3>
          </div>
         /*  <div  className='flex  items-end justify-around pt-5 gap-5'>
             <FormControlLabel control={<Checkbox value={val[1].id} name={val[1].apex_domain_name}  key={key} onChange={(e)=>{handleChangeCheck(val[1].id)}} />} label="" />
             <h3>{val[1].apex_domain_name ?? ''}{val[1].top_level_domain != 'undefined' ? val[1].top_level_domain : ''}</h3>
             </div> */
             
          )})):'No data found !'}
          {loader == true && <div  className="flex items-end justify-around pt-5 gap-5"><CircularProgress /></div>}
        {/* <div className="flex items-center gap-2">
          <p className="text-neutral-600">scan enabled</p>
          <Switch {...label} onClick={() => setToggle(!toggle)}/>
        </div> */}
        {/* { toggle && 
        <div className="flex items-center flex-col gap-5">
        <div className="flex items-center ">
          <p className="text-neutral-600">Repeat scan every</p>
          <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
            <OutlinedInput
              aria-describedby="outlined-weight-helper-text"
             value={1}
             id="days"
             name="days"
              //value={scanData?.days}
              onChange={handleChange}
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </FormControl>
          <Autocomplete
            fullWidth
            id="week"
            name='week' 
            //value={week}
            onChange={handleChange}
            options={dropdown}
            sx={{ width: 200 }}
            value={dropdown?.[0]}
            renderInput={(params) => <TextField {...params} fullWidth />}
          /> 
        </div>
            <Autocomplete
            fullWidth
            id="scan"
            name="scan"
            value={scanData?.scan}
            onChange={handleChange}
            options = {dropdown1}
            sx={{ width: 665 }}
            renderInput={(params) => (
              <TextField {...params} label="startscan"/>
            )}
          />
      </div>
        */}
        <div>
          <Button variant="contained" className="capitalize " onClick={() => scanDomainget()}>
            Start Scan
          </Button>
        </div>
      <div className='d-flex'>
      {previousLink && <Button variant="contained"  style={{textTransform: 'none'}} onClick={ () => previous()}>Previous</Button>} &nbsp;
        {nextLink && <Button variant="contained"  style={{textTransform: 'none'}} onClick={ () => next()}>Next</Button>}
        </div>
      </div>
      <div className="flex flex-col  gap-3 mx-4 p-4  mt-2  bg-white rounded-3xl shadow-md overflow-hidden w-2/4">
        <h1>Help?</h1>
        <p className="text-neutral-400">Fake domain scanner</p>
        <div className="flex flex-col gap-2">
          <p className="domainscan_guide">
            Click on “Add a domain” and enter the domain name in the text box.
          </p>
          <p className="domainscan_guide">
            Step 2: Click on “Verify” and enter a company email address you have
            access to that belongs to the same domain.
          </p>
          <p className="domainscan_guide">
            Step 3: Click on “Verify email” and a verification request will be
            sent to Truviss customer support.
          </p>
          <p className="domainscan_guide">
            Step 4: Once the email is verified you can proceed to setup the Scan
            cycle..
          </p>
          <p className="domainscan_guide">Step 6: Setup the Scan cycle.</p>
        </div>
      </div>
    </div>
  );
};

export default ScanSycle;
