import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TabTitle } from '../../../Config/generalFunctions'
import { getAllDomainData } from '../../../Redux/reducers/dashboardSlice'
import Sub_tab from '../../Components/Sub_tab'
const Index = () => {
  const domainsData = useSelector((state) => state.dahsboardData.allDomainData)
  const dispatch = useDispatch()
  useEffect(() => {
    if (domainsData === null) {
      dispatch(getAllDomainData())
    }
  }, [domainsData])
  TabTitle('Domains | Truviss')
  return (
    <div className='p-5 h-27 8  bg-gray-50 ove rflow-y-scroll'>
      <Sub_tab />
    </div>
  )
}
export default Index;
