import Checkbox from '@mui/material/Checkbox';
export const Market  = [
      {
            id:1,
            // check:<Checkbox name="Flipkart"/>,
            checked:'checked',
            domain:"Flipkart",
      },
      {
            id:2,
            // check:<Checkbox/>,
            checked:'checked',
            domain:"Amazon.com",
      },
      {
            id:3,
            // check:<Checkbox/>,
            checked:'checked',
            domain:"Amazon.co.uk",
      },
      {
            id:4,
            // check:<Checkbox/>,
            checked:'checked',
            domain:"Amazon.in",
      },
      {
            id:5,
             checked:'checked',
            domain:"Amazon.nl",
      },
]
export const Channels = [
      {
            id:1,
            check:<Checkbox/>,
            domain:"Facebook (Coming soon)",
      },
      {
            id:2,
            check:<Checkbox/>,
            domain:"Twitter (Coming soon)",
      },
      
      
]