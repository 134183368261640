import react from 'react'
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import InterestsIcon from '@mui/icons-material/Interests';
import AppsIcon from '@mui/icons-material/Apps';
import InventoryIcon from '@mui/icons-material/Inventory';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TuneIcon from '@mui/icons-material/Tune';
import WarningIcon from '@mui/icons-material/Warning';


export const sidebarData = [
    {
        tittle:"Domains",
        icon:<PublicOutlinedIcon/>,
        path:'domains',
        cName:'menu-text'
    },
    {
        tittle:"Social Media",
        icon:<InterestsIcon/>,
        path:'social_media',
        cName:'menu-text',
    },
    {
        tittle:"Apps",
        icon:<AppsIcon/>,
        path:'apps',
        cName:'menu-text'
    },
    {
        tittle:"Products",
        icon:<InventoryIcon/>,
        path:'products',
        cName:'menu-text'
    },
    {
        tittle:"Alarms",
        icon:<WarningIcon/>,
        path:'alarms',
        cName:'menu-text'
    },
    {
        tittle:"Notifications",
        icon:<NotificationsIcon/>,
        path:'notifications',
        cName:'menu-text'
    },
    {
        tittle:"Setup",
        icon:<TuneIcon/>,
        path:'setup',
        cName:'menu-text'
    }
]