import React, { useRef, useState, useEffect } from "react";
import { Paper, InputBase, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
//import "./style.scss";
import { Link, useLocation } from "react-router-dom";
import { sidebarData } from "./Sidebar/SideBarData";
import { IconContext } from "react-icons";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import logo from "../../../Assets/Icons/logo 2.png";
import logo2 from "../../../Assets/Icons/logo 3.png";
import Notifications_popup from "./Notifications_popup";
import Forgot_logout from "./Forgot&logout";

//Sidebar menu
import Apps from "../../Pages/Apps";
import Alarms from "../../Pages/Alarms";
import Domains from "../../Pages/Domains";
import Products from "../../Pages/Products";
import Notifications from "../../Pages/Notifications";
import Setup from "../../Pages/Setup";
import Socialmedia from "../../Pages/SocialMedia";
import { useNavigate, useParams } from "react-router-dom";
import { TabTitle } from "../../../Config/generalFunctions";
import getUserData from './getUserData';
const Index = ({ history }) => {
  TabTitle("Dashboard | Truviss ");
  //base URl
  const currentUrl = window.location.href; 
  const domain = window.location.hostname; 
  const location = useLocation()
  // side bar
  const [sidebar, setSidebar] = useState(true);
  const sidebarShow = () => setSidebar(!sidebar);
  const {UserData} = getUserData();

  //Header section
  const [notification, setNotification] = useState(false);
  const [user, setUser] = useState(false);
  const [userDetail, setuserData] = useState('');

  // useEffect(() => {
  //   document.addEventListener("mousedown", () => {
  //     setNotification(false);
  //     setUser(false);
  //   });
  // });
  


  //Sidebar tabs
  const { currenttab } = useParams();
  const [currentmenu, setcurrentmenu] = useState();
  let navigate = useNavigate();
  const selectmenu = (value) => {
    setcurrentmenu(value); 
    navigate(`/dash_board/${value}`);

    
  }; 
  const getData = async () => { 
    const response = await UserData(); 
    if (response.user) {
      setuserData(response.user);  
    }
  } 
  useEffect(()=>{

    getData();
    var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    if(width <= 728){
      sidebarShow(false)
    }
  },[]) 
  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }} className="flex">
        <div className="flex justify-between bg-primary  p-5 items-center z-ind"  >
          <div className="flex items-center gap-3">
            <Link
              to="#"
              className="sm:hid den px-2 active:bg-blue-600 active:rounded"
              onClick={sidebarShow}
            >
              <MenuIcon className="text-ternary " />
            </Link>
            <Link to="/logIn" className="flex">
              <img src={logo2} alt="" className="h-7 cursor-pointer sm:h-6 " />
              <img
                src={logo}
                alt=""
                className="h-7 cursor-pointer sm:h-6 sm:hidden"
              />
            </Link>
          </div>
          <div className="header_right">
            <NotificationsIcon
              className="text-ternary"
              onClick={() => {setNotification(!notification);setUser(false);}}
            />
            {notification && <Notifications_popup />}

            <MoreVertIcon
              className="text-ternary"
              onClick={() => {setUser(!user);setNotification(false);}}
            />
            {user && <Forgot_logout />}
          </div>
        </div>
        <div className="flex w-100 overflowHidden" onClick={() => { setUser(false); setNotification(false); }}>
          <div className={`menu_bar ${sidebar ? "active max-nav" : "min-nav"}`}>
            <ul className="menu_items">
              {sidebar && (
                <div className="p-2 sm:hidden border-b-2 border-gray">
                  <p className="text-lg mt-3">Acviss</p>
                  <p className="text-sm opacity-50">{userDetail.email ?? ''}</p>
                  {/* <p className="text-sm opacity-50 mb-3">Role : Admin</p> */}
                </div>
              )}
              {sidebarData?.map((data, i) => (
                <li
                  key={i}
                >
                  <a  key={i}
                  onClick={() => selectmenu(data.path)}
                  className={`sidebar_list ${
                    data.path === "setup" && location.pathname.includes('setup') ? 'menu-text' : data.path === location.pathname.split("/")[location.pathname.split("/").length - 1] && 'menu-text'
                  }`}>
                  
                  {data.path ? <span>{data.icon}</span> : ""}
                  {sidebar && (
                    <>{data.tittle}</>
                  )}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className={`${sidebar ? "max-container" : "min-container"}`}>
            {currenttab === "alarms" && <Alarms/>}
            {currenttab === "domains" && <Domains />}
            {currenttab === "social_media" && <Socialmedia />}
            {currenttab === "apps" && <Apps />}
            {currenttab === "products" && <Products />}
            {currenttab === "notifications" && <Notifications />}
            {currenttab === "setup" && <Setup />}
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
};
export default Index;
