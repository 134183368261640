import React, { useEffect } from 'react'
import Sub_tab from '../../Components/Sub_tab'
import { TabTitle } from '../../../Config/generalFunctions'
import { useDispatch, useSelector } from 'react-redux'
import { getAllAppData } from '../../../Redux/reducers/dashboardSlice'
const Index = ({}) => {
  TabTitle('Apps | Truviss')
  const allAppData = useSelector((state) => state.dahsboardData.allAppData)
  const dispatch = useDispatch()
  useEffect(() => {
    if (allAppData === null) {
      dispatch(getAllAppData())
    }
  }, [allAppData])
  return (
    <div className="h- 278 bg-gray-50 p-5   ">
      <Sub_tab/>
    </div>
  )
}
export default Index;
