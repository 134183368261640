import React, { useEffect, useState } from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom'
import Scan_Cycle from "./Scan_Cycle"
import Apps from './Apps'
import { useDispatch, useSelector } from 'react-redux';
import { getAllAppConfiq } from '../../../../Redux/reducers/setUpSlice';


const Index = () => {
      const [isApps, setIsApps] = useState(true)
      const [isscan, setIsScan] = useState("")
 const previous = () =>{
      window.history.back()
 }; 
  const allAppConfiq = useSelector((state) => state.confiqData.allAppConfiq)
  const dispatch = useDispatch()
  console.log(allAppConfiq)
  useEffect(() => {
    dispatch(getAllAppConfiq())

  }, [])

  return (
    <div className='flex flex-col gap-10  p-5 h-278 overflow-y-auto bg-gray-50'>
      <div className='flex gap-4  overflow-y-auto'>
             <KeyboardBackspaceIcon className="cursor-pointer" onClick={() =>previous()}/>
            <p>Fake Apps Scanner</p>
      </div>
      <div className="flex  gap-1 border-b h-10 text-neutral-500">
   
   <p className= {`summary  ${isApps && 'text'}`}  onClick={()=>(
     setIsApps(true),
     setIsScan(false)
     
     )}>Apps</p>
     
   <p className={`summary  ${isscan && 'text'}`}  onClick={()=>(
     setIsScan(true),
     setIsApps(false)
   )} >Start Scan</p>
 </div> 
 {isApps && 
   <Apps/>
 }
 {isscan && 
   <Scan_Cycle/>
 }

    </div>
  )
}

export default Index;