import React, { useState, useEffect } from "react";
import { TabTitle } from "../../../Config/generalFunctions";
import Fake_Domain from './Fake_Domain'
import Social_Media from './Social_Media'
import Fake_Product from './Fake_Product'
import Fake_Apps from './Fake_Apps'
import User_Management from './User_Management'
import { useLocation, useNavigate } from "react-router-dom";

const Index = () => {
  TabTitle('Setup | Truviss')
  const location = useLocation()
  const [domain, setDomain] = useState(false)
  const [category, setCategory] = useState(null)
  const [socialMedia, setSocialMedia] = useState(false)
  const [fakeProduct, setFakeProduct] = useState(false)
  const [fakeApps, setfakeApps] = useState(false)
  const [setup, setSetup] = useState(true)
  const [userManage, setUserManage] = useState(false)
  const navigate = useNavigate()
  const handleCategoryChange = (val) => {
    setSetup(false)
    setCategory(val)
    navigate(`/dash_board/setup/${val}`)
  }
  useEffect(() => {
    if (location.pathname === "/dash_board/setup") {
      setSetup(true)
      setCategory(null)
    } else {
      setCategory(location.pathname.split("/")[location.pathname.split("/").length - 1])
      setSetup(false)
    }
  }, [location.pathname])
  return (
    <>
      {setup &&
        <div className="flex flex-col gap-10  p-5  bg-gray-50">
          <div className="text-black">
            <p className="text-lg font-sans font-semibold">
              Please setup scanners to protect your brand below
            </p>
            <div className="flex flex-wrap">
              <div className="setup_card" onClick={() => (
                handleCategoryChange("fake-domain-scanner")
              )}>
                <p className="card_heading">
                  Fake Domain
                  Scanner
                </p>
                <p className="font-sans">Not Configured</p>
                <hr className="w-20 border-gray" />
              </div>
              <div className="setup_card" onClick={() => handleCategoryChange("fake-media-scanner")}>
                <p className="card_heading">
                  Fake Social  Media Scanner
                </p>
                <p className="font-sans">Not Configured</p>
                <hr className="w-20 border-gray" />
              </div>
              <div className="setup_card" onClick={() => handleCategoryChange("fake-app-scanner")}>
                <p className="card_heading">
                  Fake App
                  Scanner
                </p>
                <p className="font-sans">Not Configured</p>
                <hr className="w-20 border-gray" />
              </div>
              <div className="setup_card" onClick={() => handleCategoryChange("fake-product-scanner")}>
                <p className="card_heading">
                  Fake product
                  Scanner
                </p>
                <p className="font-sans">Not Configured</p>
                <hr className="w-20 border-gray" />
              </div>
            </div>
          </div>
          <div className="text-black">
            <p className="text-lg font-sans font-semibold">
              IAM - Identity and Access Management
            </p>
            <div className="setup_card" onClick={() => handleCategoryChange("usermanage")}>
              <p className="card_heading">
                Users
              </p>
              <p className="font-sans">Add/Modify User</p>
              <hr className="w-20 border-gray" />
            </div>
          </div>
          <div className="text-black">
            <p className="text-lg font-sans font-semibold">Feeds</p>
          </div>
        </div>
      }
      {category === "fake-domain-scanner" && (
        <Fake_Domain />
      )}
      {category === "fake-media-scanner" && (
        <Social_Media />
      )}
      {category === "fake-product-scanner" && (
        <Fake_Product />
      )}
      {category === "fake-app-scanner" && (
        <Fake_Apps />
      )}
      {category === "usermanage" && (
        <User_Management />
      )}

    </>
  );
};
export default Index;
