import API_END_POINTS from '../../../../../Config/integrations';
import { getApiResponseObject } from '../../../../Common/restApisFunctions';

const nextForm = () => {
  const nextData = async (nextLink) => { 
    
    const response = await getApiResponseObject(
        nextLink,
    );
    // var response = 'asd'
    return response;
  };
  return {
    nextData
  };
};

export default nextForm;