import API_END_POINTS from '../../Config/integrations';
import { RESPONSE_CODES } from '../../Config/definations';
import { postWithResponseObject } from '../Common/restApisFunctions';
import { openRoutes } from '../Routes';
import swal from "sweetalert2";

const RANDOM_TIMEOUT = 500;

export const setAuthCredentials = (access, refresh=null) => {
  sessionStorage.setItem("accessToken", access);
  console.log('refresh : ', refresh, 'access :', access);
  if (refresh != null) {
    localStorage.setItem("refreshToken", refresh);
  }
};

export const reloadWindow = () => {
  if (typeof window !== "undefined") {
    window.location.reload();
  }
};

/**
 * Check for accessToken existence
 * if exists then replace it with new token
 * if not exists then call getAccessAndNewRefreshToken()
 */

export const removeAuthCredentials = () => {
  localStorage.removeItem("refreshToken");
  sessionStorage.removeItem("accessToken");
};

export const logOut = (history) => {
    swal
    .fire({
      title: "Are you sure",
      text: "You want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sign Out!",
    })
    .then((result) => {
      if (result.isConfirmed) {
        removeAuthCredentials();
        swal.fire({
          text: "You have Successfully logged out !",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
        });
        setTimeout(() => {
          history('/logIn');
          window.location.reload();
        }, 3000);
      }
    });
  
   
};


/**
 * 1. Get the refresh token from localStorage
 * 2. If valid
 *   2.1. Make a POST API call with the current refresh token
 *   2.2. If status of OK, then setAuthCredentials for access and refresh token
 *        and return true else false for all other status codes
 * 3. If refresh token is unavailable then return false
 */
export const getAccessAndNewRefreshToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken) {
    const response = await postWithResponseObject(
      API_END_POINTS.userTokenRefresh,
      { 'refresh' : refreshToken }
    );

    if (response && response.status === RESPONSE_CODES.OK) {
      const { access, refresh } = response.data;
      let isAccessTokenExists = false;

      if (sessionStorage.getItem("accessToken")) {
        isAccessTokenExists = true;
      }

      if (!isAccessTokenExists) {
        reloadWindow();
      }
      setAuthCredentials(access, refresh);
      return true;
    }
    if (response && response.status === RESPONSE_CODES.UNAUTHORIZED) {
      removeAuthCredentials();
      reloadWindow();
      return false;
    }
    return false;
  }

  return false;
};

export const verifyAccessToken = async () => {
  const accessToken = sessionStorage.getItem("accessToken");

  const response = await postWithResponseObject(
    API_END_POINTS.accessTokenVerify,
    {"token":accessToken}
  );

  if (response && response.status === RESPONSE_CODES.OK) {
    return true;
  }
  if (response && response.status === RESPONSE_CODES.CREATED) {
    return true;
  }

  return false;
};

export const checkForAuth = async () => {
  if (sessionStorage.getItem("accessToken")) {
    const response = await verifyAccessToken();
    if (!response) {
      if (localStorage.getItem("refreshToken")) {
        const refreshTokenResponse = await getAccessAndNewRefreshToken();
        return refreshTokenResponse;
      }
      return false;
    }
    return response;
  }
  if (localStorage.getItem("refreshToken")) {
    const response = await getAccessAndNewRefreshToken();
    return response;
  }
  return false;
};

export const getAuthToken = () => {
  const token = sessionStorage.getItem("accessToken");
  if (!token) return undefined;
  return `Token ${token}`;
};

export const isUserAuthorized = Boolean(sessionStorage.getItem("accessToken"));

export const isRefreshTokenAvailable = Boolean(localStorage.getItem("refreshToken"));

