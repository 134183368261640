import React, { useState,useEffect } from "react";
// import domainConfig from './services'
//pushedagain
import { useLocation } from 'react-router-dom';  
import {
      Card,
      CardContent,
      Typography,
    } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
const Index = () => {
  const [allData, setAllData] = useState(0)
  const [allCritical, setCriticalData] = useState(0)
  const [allHigh, setHighData] = useState(0)
  const [allMedium, setMediumData] = useState(0)
  const [allLow, setLowData] = useState(0)
  const [loader, setLoader] = useState(false);
  const [SocialMenu, setSocialMenu] = useState(false);
  const location = useLocation(); 
  const domainData = useSelector((state) => state.dahsboardData.allDomainData)
  const socialMediaData = useSelector((state) => state.dahsboardData.allSocialMediaData)
  const allProductData = useSelector((state) => state.dahsboardData.allProductData)
  const allAppData = useSelector((state) => state.dahsboardData.allAppData)
  const requiredData = location.pathname === "/dash_board/domains" ? domainData : location.pathname === "/dash_board/apps" ? allAppData : location.pathname === "/dash_board/products" ? allProductData : socialMediaData
  const getData = async () => {
      setLoader(false);
    if (requiredData !== null) {
      setAllData(requiredData.count ?? 0);
      setCriticalData(requiredData.critical_count ?? 0);
      setHighData(requiredData.high_count ?? 0);
      setMediumData(requiredData.medium_count ?? 0);
      setLowData(requiredData.low_count ?? 0); 
    } else{
      setAllData(0); 
      setCriticalData(0); 
      setHighData(0); 
      setMediumData(0); 
      setLowData(0); 
    }
  } 

  useEffect(()=>{
    if (requiredData === null) {
      setLoader(true)
    } else {
      getData()
    }

  }, [requiredData]) 
  return (
    <div className=" flex gap-6 flex-wrap">
        {/*   {cardData.map((item, index) => {
            return ( */}
            
            
            <>
           {/*  <ReactTooltip />
            <ReactTooltip />
            <ReactTooltip />
            <ReactTooltip /> */}
              <Card
                key={1}
                style={{borderRadius:'20px'}}
                sx={{ minWidth: 150 }}
                className="flex   transition   hover:delay-300 duration-700 shadow-2xl flex-col items-center relative justify-center secondary p-2 w-44"
              >
             {/*  <InfoIcon className='text-zinc-200 self-end absolute top-2' title={allData ? 'Total '+allData : 'Total '+0}/> */}
             
          <div className="tooltip"><InfoIcon className='text-zinc-200 self-end absolute top-2' />
            <span className="tooltiptext"> Total Results : {allData ? allData : 0}</span>
              </div>
              <Link to={''}>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="text-center h-15"
                  >
                {loader !== true ? allData ?? 0 : null}
                {loader == true && <div className="text-center"><CircularProgress /></div>}
                  </Typography>
                  <Typography variant="body2"   className="text-center secondary">
                   Total Results
                  </Typography>
                </CardContent>
                </Link>
              </Card>
              <Card
                key={2}
                style={{borderRadius:'20px'}}
                sx={{ minWidth: 150 }}
                className="flex overflow-visible  transition hover:scale-110 hover:delay-300 duration-700 shadow-2xl flex-col items-center relative justify-center secondary p-2 w-44"
              >
             {/*  <InfoIcon className='text-zinc-200 self-end absolute top-2' data-tip={allCritical ? 'Critical '+allCritical : 'Critical '+0}/> */}
          <div className="tooltip"><InfoIcon className='text-zinc-200 self-end absolute top-2' />
            <span className="tooltiptext">{SocialMenu ? <>Abusive : {allCritical ?? 0}</> : <>These listings have fake/counterfeit score of more than 90</>}</span>
              </div>
              <Link to={''}>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="text-center h-15"
                  >
                {loader !== true ? allCritical ?? 0 : null}
                {loader == true && <div className="text-center"><CircularProgress /></div>}
                  </Typography>
                  <Typography variant="body2" className="text-center secondary">
                   {SocialMenu ? <>Abusive</>: <>Critical</>}
                  </Typography>
                </CardContent>
                </Link>
              </Card>
              <Card
                key={3}
                style={{borderRadius:'20px'}}
                sx={{ minWidth: 150 }}
                className="flex   overflow-visible  transition hover:scale-110 hover:delay-300 duration-700 shadow-2xl flex-col items-center relative justify-center secondary p-2 w-44"
              >
             {/*  <InfoIcon className='text-zinc-200 self-end absolute top-2' data-tip={'High '+allHigh ?? 0}/> */}
          <div className="tooltip"><InfoIcon className='text-zinc-200 self-end absolute top-2' />
            <span className="tooltiptext"> {SocialMenu ? <>Profanity : {allHigh ?? 0}</> : <>These listings have fake/counterfeit score between 70-89</>}</span>
              </div>
              <Link to={''}>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="text-center h-15"
                  >
                {loader !== true ? allHigh ?? 0 : null}
                {loader == true && <div className="text-center"><CircularProgress /></div>}
                  </Typography>
                  <Typography variant="body2" className="text-center secondary">
                  {SocialMenu ? <>Profanity</>: <>High</>}
                  </Typography>
                </CardContent>
                </Link>
              </Card>
              <Card
                key={4}
                style={{borderRadius:'20px'}}
                sx={{ minWidth: 150 }}
                className="flex  overflow-visible  transition hover:scale-110 hover:delay-300 duration-700 shadow-2xl flex-col items-center relative justify-center secondary p-2 w-44"
              >
          <div className="tooltip"><InfoIcon className='text-zinc-200 self-end absolute top-2' />
            <span className="tooltiptext">{SocialMenu ? <>Malicious : {allMedium ?? 0}</> : <>These listings have fake/counterfeit score between 50-69</>}</span>
              </div>
             {/*  <InfoIcon className='text-zinc-200 self-end absolute top-2' data-tip={'Medium '+allMedium ?? 0}/> */}
              <Link to={''}>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="text-center h-15"
                  >
                {loader !== true ? allMedium ?? 0 : null}
                {loader == true && <div className="text-center"><CircularProgress /></div>}
                  </Typography>
                  <Typography variant="body2" className="text-center secondary">
                  {SocialMenu ? <>Malicious</>: <>Medium</>}
                  </Typography>
                </CardContent>
                </Link>
              </Card>
              <Card
                key={5}
                style={{borderRadius:'20px'}}
                sx={{ minWidth: 150 }}
                className="flex  overflow-visible   transition hover:scale-110 hover:delay-300 duration-700 shadow-2xl flex-col items-center relative justify-center secondary p-2 w-44"
              >
          <div className="tooltip"><InfoIcon className='text-zinc-200 self-end absolute top-2' />
            <span className="tooltiptext"> {SocialMenu ? <>Impersonation : {allLow ?? 0}</> : <>These listings have fake/counterfeit score between 30-49</>}</span>
              </div>
             {/*  <InfoIcon className='text-zinc-200 self-end absolute top-2' data-tip={'Low '+allLow ?? 0}/> */}
              <Link to={''}>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="text-center h-15"
                  >
                {loader !== true ? allLow ?? 0 : null}
                {loader == true && <div className="text-center"><CircularProgress /></div>}
                  </Typography>
                  <Typography variant="body2" className="text-center secondary">
                   {SocialMenu ? <>Impersonation</>: <>Low</>}
                  </Typography>
                </CardContent>
                </Link>
              </Card>
              </>
              
              
           {/*  ); */}
        {/*   })} */}
    </div>
  )
}

export default Index;