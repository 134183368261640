import API_END_POINTS from '../../../../../Config/integrations';
import { getApiResponseObject } from '../../../../Common/restApisFunctions';

const getAllProducts = () => { 
  const products = async () => {  
    const response = await getApiResponseObject(
      API_END_POINTS.productConfigGet, 
      JSON.stringify(),
    );  
    return response;
  }; 
  return {
    products
  };
};
export default getAllProducts;