import API_END_POINTS from '../../../../../Config/integrations';
import { postWithResponseObject } from '../../../../Common/restApisFunctions';

const appScan = () => { 
         
      const startScanApps = async (id) => {
        let item =  {
          app_analyser_config:id, 
        } ;
        const response = await postWithResponseObject(
          API_END_POINTS.appScanPost,
          JSON.stringify(item),
        );
        return response;
      };
      return {
        startScanApps
      };
};  
export default appScan;