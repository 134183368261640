export const columns = [
      { name: "name", label: "Name" },
      { name: "email", label: "Email" },
      { name: "phone", label: "Phone" },
      { name: "role", label: "Role" },
      { name: "edit", label: "Edit" },
    ];
export const data = [
      {
        name: "Joe James",
        email: "Test@acviss.com",
        phone: "9875663438",
        role: "Admin",
        edit: "Test Corp",
       
      },
      {
        name: "Pols",
        email: "pols@acviss.com",
        phone: "9875663438",
        role: "Viewer",
        edit: "Test Corp",
       
      },
      {
        name: "polaiah",
        email: "polaiah@acviss.com",
        phone: "9875663438",
        role: "Admin",
        edit: "Test Corp",
       
      },
    ];
