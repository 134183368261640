import API_END_POINTS from '../../../Config/integrations';
import { getApiResponseObject } from '../../Common/restApisFunctions';

const getUserData = () => { 
  const UserData = async () => {  
    const response = await getApiResponseObject(
      API_END_POINTS.userGet, 
      JSON.stringify(),
    );  
    return response;
  }; 
  return {
    UserData
  };
};

export default getUserData;