import API_END_POINTS from '../../../Config/integrations';
import { getApiResponseObject } from '../restApisFunctions';

const getAllsocial = () => { 
  const social = async () => {  
    const response = await getApiResponseObject(
      API_END_POINTS.socialResultGet, 
      JSON.stringify(),
    );  
    return response;
  }; 
  return {
    social
  };
};
export default getAllsocial;