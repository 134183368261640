import React, {useState,useRef} from 'react'
import TextField from "@material-ui/core/TextField";
import { Box, Button } from "@mui/material";
// import {API_BASE_URL} from '../../../Config/env'
import {useNavigate} from 'react-router-dom'
import {RESPONSE_CODES} from '../../../Config/definations'
// import { setAuthCredentials } from "../../Common/authUtils";
import UseSignupForm from './services';
import {Link} from 'react-router-dom'
import swal from 'sweetalert';
import { TabTitle } from "../../../Config/generalFunctions";

const Index= () => {
  TabTitle(" Sign Up | Truviss ");
      const [email, setEmail] = useState("")
      const [username, setUsername] = useState("")
      const [password, setPassword] = useState("")
      //const [successMsg, setSuccessMsg] = useState([])
      const [errMsg, setErrMsg] = useState([]) 
      const errRef = useRef()
      //const userRef = useRef()
      const {signupUser} = UseSignupForm();
      const history =useNavigate()
      const [disabled, setDisabled] = useState(false);
      const signUp = async () =>{
        setErrMsg('');
        if(email == ''){
          // setErrMsg('Email is required');
          swal({
            title:"Error!",
            text:"Email is required",
            icon:"warning",
            button:"Ok"
          });
          return;
        }
        if(username == ''){
          // setErrMsg('Username is required');
          swal({
            title:"Error!",
            text:"Username is required",
            icon:"warning",
            button:"Ok"
          });
          return;
        }
        if(password == ''){
          // setErrMsg('Password is required');
          swal({
            title:"Error!",
            text:"Password is required",
            icon:"warning",
            button:"Ok"
          });
          return;
        }
            const response = await signupUser(email,username, password);
            let error = response.status 
              if (response && (response.status === RESPONSE_CODES.ERROR)) {
              //  setErrMsg("Either email/username already  has been existed")  
                swal({
                  title:"Error!",
                  text:"Either email/username already  has been existed",
                  icon:"warning",
                  button:"Ok"
                });
                setDisabled(false)
              }else {
                setErrMsg(false)
                swal({
                    title:"Success!",
                    text:"Your registration is successfull",
                    icon:"success",
                    button:"Sign In !"
                }).then(function() {
                    window.location.href = "logIn";
                })
              }
            // let data = {user : {email,username,password}}
            // console.log('register data', data)
            // let response = await fetch (API_BASE_URL + "/register/",{
            //       method : 'POST',
            //       body: JSON.stringify(data),
            //       headers : {
            //          "Content-Type": 'application/json',
            //          "Accept" : 'application/json'
            //       }
               
            // })
            // setSuccessMsg("You are registered Successfully")
            // if (response && (response.status === RESPONSE_CODES.OK)) {
            //       const { access, refresh } = response.data.tokens;
            //       setAuthCredentials(access, refresh);
            //       setSuccessMsg("You are registered Successfully")
            
            //     } 
            //     else if (response && (response.status === RESPONSE_CODES.CREATED)) {
            //       setSuccessMsg("You are registered Successfully")

            //     } 
            //     else if (response && (response.status === RESPONSE_CODES.ERROR)) {
            //       setErrMsg("either E-mail/username already has been  existed")

            //     } 
            //     else {
            //       setSuccessMsg(response.data.user.username[0])
            //     }
            // response = await response.json()
            // console.log("result45",response)
            // localStorage.setItem("user-info",JSON.stringify(response))
            //history.push("/logIn")
            
      }

  return (
    <div className="flex  flex-col gap-5 h-screen pt-10 ">
      <form className="flex flex-col gap-5  items-center bg-white rounded-3xl shadow-md overflow-hidden p-10 w-1/4 self-center ml-auto mr-auto" >
      <h3 className='text-center mb-10 text-lg tracking-widest'>Register</h3>
      <p ref={errRef} aria-live="asertive" className="bg-red-100 self-center  text-red-600 font-sans  text-base  text-center rounded-md w-96 align-middle font-semibold  tracking-widest ml-10 mr-10">{errMsg}</p> 
             <TextField
               required
               id="email_address"
               name="email"
               value={email}
               label="E-mail"
               variant="outlined"
               onChange={(e) => setEmail(e.target.value)}
               fullWidth
             />
             <TextField
               required
               id="user_name"
               name="username"
               value={username}
               label="username"
               variant="outlined"
               onChange={(e) => setUsername(e.target.value)}
               fullWidth
             />
             <TextField
               required
               id="password"
               name="password"
               value={password}
               label="password"
               variant="outlined"
               type="password"
               onChange={(e) => setPassword(e.target.value)}
               fullWidth
             />
             <Box className="flex justify-center">
               <Button
                 variant="contained"
                 className="log_signin_btn"
                // disabled={   username === "" || password === "" ? true : false}
                // disabled={disabled}
                onClick={() => (
                  signUp(),
                  setDisabled(!disabled)
                )}
               >
                 SIGN UP
               </Button>
             </Box>
             <p className="log_in_bottom">
              Have an account?&nbsp;
              <Link to="/login"><span style={{color:'#1976d2'}} >Log In</span></Link>
            </p>
             </form> 
    </div>
  )
}

export default Index