import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API_END_POINTS from '../../Config/integrations';
import { getApiResponseObject } from '../../Web/Common/restApisFunctions';

const initialState = {
  allDomainData: null,
  allSocialMediaData: null,
  allAppData: null,
  allProductData: null
}
export const getAllDomainData = createAsyncThunk(
  'data/get ',
  async () => {
    const res = await getApiResponseObject(
      API_END_POINTS.domainsResultsGet,
      JSON.stringify(),
    );
    return res
  })
export const getAllSocialMediaData = createAsyncThunk(
  'socialdata/get ',
  async () => {
    const res = await getApiResponseObject(
      API_END_POINTS.socialResultGet,
      JSON.stringify(),
    );
    return res
  })
export const getAllAppData = createAsyncThunk(
  'appdata/get ',
  async () => {
    const res = await getApiResponseObject(
      API_END_POINTS.appResultsGet,
      JSON.stringify(),
    );
    return res
  })
export const getAllProductData = createAsyncThunk(
  'productData/get ',
  async () => {
    const res = await getApiResponseObject(
      API_END_POINTS.productResultGet,
      JSON.stringify(),
    );
    return res
  })
export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {

  },
  extraReducers: {

    [getAllDomainData.fulfilled]: (state, { payload }) => {

      state.allDomainData = payload
    },
    [getAllSocialMediaData.fulfilled]: (state, { payload }) => {

      state.allSocialMediaData = payload
    },
    [getAllAppData.fulfilled]: (state, { payload }) => {

      state.allAppData = payload
    },
    [getAllProductData.fulfilled]: (state, { payload }) => {

      state.allProductData = payload
    },

  },
})



export default dashboardSlice.reducer