import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Modal, TextField, Autocomplete } from "@mui/material";
import {data} from "../Json_data";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Index = ({ setUserEdit }) => {
  const [open, setOpen] = useState(true);
  const dropdown = ['Admin', 'Maintainer','Viewer'];
  return (
    <div>
      <Modal
        open={open}
        //onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: 1200 }} className="flex flex-col">
          <div>
            <h4 className="text-md">Edit</h4>
          </div>
          <div className="flex flex-col gap-6 p-5">
            <TextField
              value={data?.name}
              id="name"
              name="name"
              label="Name"
              required={true}
              variant="outlined"
              type="text"
              fullWidth
            />
            <TextField
              id="e_mail"
              name="e_mail"
              label="E-Mail"
              variant="outlined"
              type="text"
              fullWidth
            />
            <TextField
              id="name"
              name="phone"
              label="Phone"
              required={true}
              variant="outlined"
              type="text"
              fullWidth
            />
            <Autocomplete
              disablePortal
              fullWidth
              options={dropdown?.length ? dropdown : []}
              name="Role"
              renderInput={(params) => <TextField {...params} label="Role" />}
            />
          </div>
          <div className="flex justify-end">
            <Button
              onClick={() => setUserEdit(false)}
              style={{ textTransform: "none" }}
            >
              Update user
            </Button>
            <Button
              onClick={() => setUserEdit(false)}
              color="warning"
              className="self-end"
              style={{ textTransform: "none" }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
export default Index;
