import API_END_POINTS from '../../../Config/integrations';
import { postWithResponseObject } from '../../Common/restApisFunctions';

const UseSignupForm = () => {
  const signupUser = async (email,username, password) => {
    let item = { user: { email,username, password } };
    const response = await postWithResponseObject(
      API_END_POINTS.register,
      JSON.stringify(item),
    );
    return response;
  };
  return {
      signupUser
  };
};

export default UseSignupForm;